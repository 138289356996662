<div class="container-fluid px-0 top-header">
	<div class="nav">
		<a
			href="#"
			class="logo nav-link">
			<div>
				<pichincha-brand
					type="logo"
					color="white"
					width="113px">
				</pichincha-brand>
			</div>
		</a>

		<ul class="nav-menu">
			<div class="hidden-logout d-md-flex d-sm-flex d-lg-flex">
				<li
					class="nav-menu-item nav-menu-item__logout"
					(click)="logout.emit()">
					<pichincha-icon
						class="padding-icon"
						size="big"
						color="white"
						weight_color="900"
						type="--outlined">
						exit_to_app
					</pichincha-icon>
					<div class="hidden-md">
						<pichincha-typography
							variant="smallText"
							color="white"
							weight="normal"
							weight_color="500"
							align="inherit">
							{{ 'header.cerrar' | translate }}
						</pichincha-typography>
					</div>
				</li>
			</div>
		</ul>
	</div>
</div>
