<router-outlet></router-outlet>

<app-alert-modal [open]="open$ | async">
	<app-session-alert [open]="open$ | async"></app-session-alert>
</app-alert-modal>

<app-alert-modal [open]="userService.expiredPasswordModalInfo.expiring">
	<app-password-alert-modal></app-password-alert-modal>
</app-alert-modal>

<app-alert-modal [open]="userService.dataModalDuoubleSesion.modalOpen">
	<app-session-double-active #modal></app-session-double-active>
	<!-- TODO: SOCKET -->
	<!-- <app-session-double-active
    #modal
    (modalKeep)="responseActionModalDoubleSession($event)"
  ></app-session-double-active> -->
</app-alert-modal>

<app-alert-modal [open]="websocketSrv.sessionModal$ | async">
	<app-session-double-active (modalKeep)="responseActionModalDoubleSession()"></app-session-double-active>
</app-alert-modal>

<!-- <ng-template #template let-currentStepNumber="currentStepNumber" let-totalSteps="totalSteps"
  >I'm in {{currentStepNumber}} of {{totalSteps}}</ng-template
> -->
