import { Injectable } from '@angular/core';
import { Router, CanActivate, NavigationStart } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { AccountInfo } from '@azure/msal-common';
import { EventBusService } from '@pichincha/angular-sdk/eventbus';
import { StorageService } from '@pichincha/angular-sdk/storage';
import { environment } from '../../../../environments/environment';
/**
 * Services
 */
import { UserService } from '../../services/user.service';
/**
 * Config
 */
import { General } from '../../../core/config/general.enum';
/**
 * Interfaces
 */
import { HelperMenuService } from '../../services/menu/helper.menu.service';
import { SidebarInterface } from '../../../core/interfaces/sidebar.interface';
@Injectable()
export class UserProfileGuard implements CanActivate {
	sidebar: SidebarInterface[] = [];
	route: any;
	constructor(
		private service: UserService,
		private msal: MsalService,
		private event: EventBusService,
		private router: Router,
		private storage: StorageService,
		private helperService: HelperMenuService,
		private storageService: StorageService,
	) {
		const jwt = this.storageService.get('access_token');
		this.sidebar = jwt ? this.helperService.getFilterMenuBase(jwt) : [];
	}

	public async canActivate(): Promise<boolean> {
		/**
		 * If data exist en eventbus, return true,
		 * if validate url microapps
		 */
		let _this = this;
		this.router.events.subscribe(function (s) {
			if (s instanceof NavigationStart) {
				_this.storage.set('url', s.url.substring(1));
			}
		});
		this.route = this.storageService.get('url');
		const data = this.event.getValue(General.CHANNEL_USER, General.TOPIC_USER);
		if (data) {
			return this.checkUrl();
		}

		/**
		 * If auth_token dont exist, emit event
		 * to close session
		 * it is complete
		 */

		await this.msal.handleRedirectObservable().toPromise();
		const accounts: Array<AccountInfo> = this.msal.instance.getAllAccounts();

		if (accounts.length > 0) {
			try {
				this.storageService.set(environment.storage.appName, 'bussines-banking');

				await this.service.getUserInformation();

				const data_guard = this.event.getValue(General.CHANNEL_USER, General.TOPIC_USER);

				let check = this.checkUrl();

				data_guard ?? check === false ?? this.router.navigateByUrl('/');
				return Promise.resolve(true);
			} catch (e) {
				await this.msal.loginRedirect({ scopes: environment.authProvider.scopes }).toPromise();
				return Promise.resolve(false);
			}
		}

		return Promise.resolve(false);
	}

	checkUrl(): boolean {
		let dataPaths: string[] = [];
		this.sidebar.forEach((sid) => {
			if (sid.chields) {
				sid.chields.forEach((chield) => {
					dataPaths.push(chield.path);
				});
			} else {
				dataPaths.push(sid.path);
			}
		});

		return dataPaths.find((s) => s === this.route) ? true : false;
	}
}
