import { Component, ElementRef, EventEmitter, OnDestroy, Output, ViewChild } from '@angular/core';
import { TourService } from '../../../core/services/tour-service/tour.service';
import { UserService } from '../../../core/services/user.service';

@Component({
	selector: 'app-guided-tour-modal',
	templateUrl: './guided-tour-modal.component.html',
	styleUrls: ['./guided-tour-modal.component.scss'],
})
export class GuidedTourModalComponent implements OnDestroy {
	@Output()
	openTourEvent: EventEmitter<boolean> = new EventEmitter<boolean>();

	@ViewChild('modal', { static: true }) modal: ElementRef = {} as ElementRef;
	constructor(
		public userService: UserService,
		public tourService: TourService,
	) {}

	public titleStyle = {
		fontSize: '24px',
		lineHeight: '32px',
	};

	ngOnDestroy(): void {
		this.userService.guidedTourModal.modalOpen = false;
	}

	/**
	 * closeModal
	 *
	 * @description Metodo para cerrar el modal
	 */
	closeModal(): void {
		this.userService.guidedTourModal.modalOpen = false;
		this.tourService.seeLaterTour();
	}

	startTour() {
		this.openTourEvent.emit(true);
	}
}
