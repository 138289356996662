import { SidebarInterface } from 'src/app/core/interfaces/sidebar.interface';

export const sidebar: SidebarInterface[] = [
	{
		icon: 'equalizer',
		title: 'side.Posicion_consolidada',
		path: 'consolidate-position',
		menu: 'POS.CTA.SAL',
		extraPath: '',
		property: false,
	},
	{
		icon: '',
		title: '',
		path: 'credit-list/list',
		menu: 'POS.CTA.SAL',
		extraPath: '',
		property: true,
	},

	{
		icon: 'sync_alt',
		title: 'side.Transferencias',
		path: '',
		menu: 'TRCTAPR.CTA.REG',
		extraPath: '',
		property: false,
		chields: [
			{
				icon: '',
				title: 'side.Cuentas_Propias',
				path: 'tranfers/own-transfer',
				menu: 'TRCTAPR.CTA.REG',
				extraPath: '',
				property: true,
			},
			{
				icon: '',
				title: 'side.Cuentas_Terceros',
				path: 'direct-third-transfer',
				menu: 'TRTE.CTA.REG',
				extraPath: '',
				property: true,
			},
			{
				icon: '',
				title: 'side.Interbancaria',
				path: 'interbank-transfer',
				menu: 'TRINTB.CTA.REG',
				extraPath: '',
				property: true,
			},
			{
				icon: '',
				title: '',
				path: 'tranfers',
				menu: 'TRCTAPR.CTA.REG',
				// TODO: Original
				// menu: "TRX.CTA.CON",
				extraPath: '',
				hidde: true,
			},
			{
				icon: '',
				title: '',
				path: 'tranfers',
				menu: 'TRTE.CTA.REG',
				// TODO: Original
				// menu: "TRX.CTA.CON",
				extraPath: '',
				hidde: true,
			},
		],
	},
	// {
	//   icon: "sync_alt",
	//   title: "side.Transferencias",
	//   path: "tranfers",
	//   menu: "TRX.CTA.CON",
	//   extraPath: "",
	//   property: false,
	// },
	// {
	//   icon: "attach_money",
	//   title: "side.Pagos",
	//   path: "payment",
	//   menu: "PAG.CTA.CON",
	//   extraPath: "",
	//   property: false,
	// },
	{
		icon: 'monetization_on',
		title: 'side.Autodesembolso',
		path: 'self-disbursement',
		menu: 'SLFDBMNT.CTA.CRE',
		extraPath: '',
		property: false,
	},
	// {
	//   icon: "money_off",
	//   title: "side.Solicitud_desembolso",
	//   path: "",
	//   // TODO: MOCK
	//   menu: "POS.CTA.SAL",
	//   extraPath: "",
	//   property: false,
	//   chields: [
	//     {
	//       icon: "",
	//       title: "side.Productos",
	//       path: "product-request",
	//       menu: "POS.CTA.SAL",
	//       extraPath: "",
	//       property: false,
	//     },
	//     {
	//       icon: "",
	//       title: "",
	//       path: "working-capital/working-capital",
	//       menu: "POS.CTA.SAL",
	//       extraPath: "",
	//       hidde: true,
	//     },
	//     {
	//       icon: "",
	//       title: "",
	//       path: "working-capital/import-export",
	//       menu: "POS.CTA.SAL",
	//       extraPath: "",
	//       hidde: true,
	//     },
	//   ],
	// },
	// {
	//   icon: "account_balance_wallet",
	//   title: "side.Cobros",
	//   path: "orders",
	//   menu: "CBR.CTA.CON",
	//   extraPath: "",
	//   property: true,
	// },
	{
		icon: 'list_alt',
		title: 'side.Administracion_ordenes',
		path: 'orders-administration',
		menu: 'ORD.CRG.CON',
		extraPath: '',
		property: false,
	},
	{
		icon: 'list_alt',
		title: 'side.Administracion_ordenes',
		path: 'orders-administration',
		menu: 'ORD.APR.CON',
		extraPath: '',
		property: false,
	},
	// {
	//   icon: "monetization_on",
	//   title: "side.Cash_Management",
	//   path: "cash-management",
	//   menu: "CASH.MNG.CON",
	//   extraPath: "",
	//   property: false,
	// },
	// {
	//   icon: "payment",
	//   title: "side.Linea",
	//   path: "credits-disbursement",
	//   menu: "CRED.CTA.CON",
	//   extraPath: "",
	//   property: true,
	// },
	// {
	//   icon: "laptop",
	//   title: "side.Solicitar",
	//   path: "products-services",
	//   menu: "PROD.SRV.CON",
	//   extraPath: "",
	//   property: true,
	// },
	// {
	//   icon: "featured_play_list",
	//   title: "side.Otras_solicitudes",
	//   path: "",
	//   menu: "POS.CTA.OSL",
	//   extraPath: "",
	//   property: true,
	//   chields: [
	//     {
	//       icon: "",
	//       title: "side.Referencia_Bancaria",
	//       path: "certificate-request",
	//       menu: "POS.CTA.REF",
	//       extraPath: "",
	//       property: false,
	//     },
	//     {
	//       icon: "",
	//       title: "side.Movimientos_Historicos",
	//       path: "mov-hist",
	//       menu: "POS.CTA.MHT",
	//       extraPath: "",
	//       property: true,
	//     },
	//   ],
	// },
	// {
	//   icon: "people",
	//   title: "side.Admin",
	//   path: "user-management",
	//   menu: "ADM.USER.CON",
	//   extraPath: "",
	//   property: false,
	// },
	// {
	//   icon: "question_answer",
	//   title: "side.Preguntas",
	//   menu: "FAQ.SD.CON",
	//   path: "faq",
	//   extraPath: "",
	//   property: false,
	// },
	{
		icon: '',
		title: '',
		menu: 'ORD.APR.CON',
		path: 'softoken',
		extraPath: '',
		property: true,
	},
	{
		icon: '',
		title: '',
		menu: 'ORD.CRG.CON',
		path: 'softoken',
		extraPath: '',
		property: true,
	},
	// {
	//   icon: "",
	//   title: "",
	//   path: "user-creation/user-confirm-creation",
	//   menu: "ADM.USER.CRE",
	//   extraPath: "",
	//   property: true,
	// },
	// {
	//   icon: "",
	//   title: "",
	//   path: "user-creation/user-profile",
	//   menu: "ADM.USER.CRE",
	//   extraPath: "",
	//   property: true,
	// },
	{
		icon: '',
		title: '',
		path: 'softoken/successful-process',
		menu: 'ADM.USER.CRE',
		extraPath: '',
		property: true,
	},
	// {
	//   icon: "",
	//   title: "",
	//   path: "user-detail/user-confirm-modification",
	//   menu: "ADM.USER.CON",
	//   extraPath: "",
	//   property: true,
	// },
	// {
	//   icon: "",
	//   title: "",
	//   path: "user-detail/user-config-edit",
	//   menu: "ADM.USER.MOD",
	//   extraPath: "",
	//   property: true,
	// },
	// {
	//   icon: "",
	//   title: "",
	//   path: "user-management/user-activation",
	//   menu: "ADM.USER.ACT",
	//   extraPath: "",
	//   property: true,
	// },
	// {
	//   icon: "",
	//   title: "",
	//   path: "user-management/user-activation/deactivation",
	//   menu: "ADM.USER.DESACT",
	//   extraPath: "",
	//   property: true,
	// },
	// {
	//   icon: "",
	//   title: "",
	//   path: "direct-third-transfer",
	//   menu: "TRCTAPR.CTA.REG",
	//   extraPath: "",
	//   property: true,
	// },
	// {
	//   icon: "",
	//   title: "",
	//   path: "tranfers/own-transfer",
	//   menu: "TRCTAPR.CTA.REG",
	//   extraPath: "",
	//   property: true,
	// },
	{
		icon: '',
		title: '',
		path: 'orders-administration/order-send',
		menu: 'ORD.CRG.CON',
		extraPath: '',
		property: true,
	},
	{
		icon: '',
		title: '',
		path: 'orders-administration/order-send',
		menu: 'ORD.APR.CON',
		extraPath: '',
		property: true,
	},
	{
		icon: '',
		title: '',
		path: 'account-movements/details',
		menu: 'POS.CTA.MOV',
		extraPath: '',
		property: true,
	},
	{
		icon: '',
		title: '',
		path: 'orders-administration/order-approve',
		menu: 'ORD.APR.CON',
		extraPath: '',
		property: true,
	},
	{
		icon: 'call',
		title: 'side.Contactos',
		path: 'contacts',
		menu: 'POS.CTA.SAL',
		extraPath: '',
		property: false,
	},
];
