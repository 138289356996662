/**
 * Angular Core / Router
 */
import { NgModule } from '@angular/core';
import { Routes, PreloadAllModules } from '@angular/router';

/**
 * Pichincha
 */
import {
	LoaderFederationModule,
	LoaderConfigurationFederated,
} from '@pichincha/angular-sdk/module-federation';

/**
 * environments
 */
import { environment } from '../environments/environment';

/**
 * Components
 */
import { EmptyComponent } from './shared/components/empty/empty.component';

/**
 * Layouts
 */
import { DashboardLayoutComponent } from './layouts/dashboard-layout/dashboard-layout.component';
import { FullPageLayoutComponent } from './layouts/full-page-layout/full-page-layout.component';

/**
 * Guards
 */
import { LoggedGuard } from './core/guards/logged/logged.guard';
import { UserProfileGuard } from './core/guards/user-profile/user-profile.guard';

const routes: Routes = [
	{
		path: '',
		component: EmptyComponent,
		canActivate: [LoggedGuard],
	},
	/**
	 * Path reserver for msal redirect
	 * not erase o modify
	 */
	{
		path: 'state',
		canActivate: [LoggedGuard],
		component: EmptyComponent,
	},
];

const configuration: LoaderConfigurationFederated = {
	remoteConfigurationUrl: environment.microApps,
	extra: { useHash: true, preloadingStrategy: PreloadAllModules },
	layouts: [
		{
			component: DashboardLayoutComponent,
			name: 'dashboard',
			onEnterRoute: [UserProfileGuard],
		},
		{
			component: FullPageLayoutComponent,
			name: 'fullpage',
		},
	],
};

@NgModule({
	imports: [LoaderFederationModule.forRoot(routes, configuration)],
	exports: [LoaderFederationModule],
})
export class AppRoutingModule {}
