import { EStorageType } from '@pichincha/typescript-sdk';
import { environment } from '../../../environments/environment';
import { PublicClientApplication } from '@azure/msal-browser';

/**
 * Configuration for storage in browser
 */
export const ConfigStorage = {
	storageType: EStorageType.SESSION,
	secretKey: environment.storage.key,
};

const isIE =
	window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

/**
 * Configuration for MSAL Auth
 */
export const AuthApplication = new PublicClientApplication({
	auth: {
		clientId: environment.authProvider.clientId,
		authority: environment.authProvider.autority,
		redirectUri: environment.authProvider.redirectUrl,
		knownAuthorities: [environment.authProvider.domain],
	},
	cache: {
		cacheLocation: 'sessionStorage',
		storeAuthStateInCookie: isIE,
	},
});
