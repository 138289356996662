<div
	#modal
	class="container"
	(click)="close()">
	<div class="content">
		<div class="row title">
			<div class="col-12">
				<pichincha-typography
					weight="bold"
					color="blue"
					weight_color="500"
					align="inherit">
					Banca Empresas
				</pichincha-typography>
			</div>
			<div class="col-12 divider"></div>
		</div>
		<div
			*ngFor="let item of sidebar"
			routerLinkActive
			#menu="routerLinkActive">
			<div
				class="row item"
				[routerLink]="'/' + item.path"
				routerLinkActive="active-link">
				<div class="col-12">
					<ng-container>
						<div class="row">
							<div class="col-4">
								<pichincha-icon
									size="big"
									[color]="menu.isActive ? 'blue' : 'darkGrey'"
									[weight_color]="menu.isActive ? '500' : '400'"
									type="--outlined">
									{{ item.icon }}
								</pichincha-icon>
							</div>
							<div class="col-8">
								<pichincha-typography
									[weight]="menu.isActive ? 'bold' : 'normal'"
									[color]="menu.isActive ? 'blue' : 'darkGrey'"
									[weight_color]="menu.isActive ? '500' : '400'"
									align="inherit"
									[inline_styles]="labelstyle">
									{{ item.title | translate }}
								</pichincha-typography>
							</div>
						</div>
					</ng-container>
				</div>
			</div>
			<div
				class="row item property"
				*ngIf="item.property">
				<div class="col-12 divider"></div>
			</div>
		</div>
	</div>
</div>
