<div
	[ngClass]="{ 'sidebar-menu': !hiden, 'sidebar-colapse': hiden }"
	class="hidden-md">
	<!-- {{ sidebar | json }} -->

	<ng-container *ngFor="let item of sidebar; let last = last">
		<ng-container *ngIf="!item.hidde && item.title === 'side.Demo'">
			<div
				routerLinkActive
				#menu="routerLinkActive">
				<div
					class="row side"
					class="row side"
					(click)="redirect()"
					routerLinkActive="active-link">
					<div
						class="col-2 p-0 m-0 margin-auto"
						*ngIf="menu.isActive && item.icon !== 'payment'">
						<pichincha-icon
							size="big"
							color="blue"
							weight_color="500"
							type="--outlined">
							{{ item.icon }}
						</pichincha-icon>
					</div>

					<div
						class="col-9 p-0 m-0"
						*ngIf="menu.isActive">
						<pichincha-typography
							weight="bold"
							color="blue"
							weight_color="500"
							align="inherit"
							[inline_styles]="labelstyle">
							{{ item.title | translate }}
						</pichincha-typography>
					</div>

					<div
						class="col-2 p-0 m-0"
						*ngIf="!menu.isActive && item.icon !== 'payment'">
						<pichincha-icon
							size="big"
							color="darkGrey"
							weight_color="400"
							type="--outlined">
							{{ item.icon }}
						</pichincha-icon>
					</div>

					<div
						class="col-9 p-0 m-0 hidden-md"
						*ngIf="!menu.isActive">
						<pichincha-typography
							weight="normal"
							color="darkGrey"
							weight_color="400"
							align="inherit"
							[inline_styles]="labelstyle">
							{{ item.title | translate }}
						</pichincha-typography>
					</div>
				</div>
			</div>
			<div
				class="row item"
				*ngIf="last">
				<div class="col-12 divider"></div>
			</div>
		</ng-container>
	</ng-container>

	<!-- template para icono del sidebar -->
	<ng-template
		#iconTemplate
		let-item="item"
		let-menu="menu">
		<pichincha-icon
			size="big"
			[color]="menu.isActive ? 'blue' : 'darkGrey'"
			[weight_color]="menu.isActive ? '500' : '400'"
			type="--outlined">
			<ng-container *ngIf="item.icon !== 'payment'">
				{{ item.icon }}
			</ng-container>

			<ng-container *ngIf="item.icon === 'payment'">
				<svg
					width="24"
					height="24"
					viewBox="0 0 24 24"
					fill="none"
					xmlns="http://www.w3.org/2000/svg">
					<path
						fill-rule="evenodd"
						clip-rule="evenodd"
						d="M2 7C2 5.89543 2.89543 5 4 5H6V7H4V17H6V19H4C2.89543 19 2 18.1046 2 17V7Z"
						fill="#6E6E73" />
					<path
						fill-rule="evenodd"
						clip-rule="evenodd"
						d="M22 17C22 18.1046 21.1046 19 20 19L13 19V17H20L20 7L13 7V5L20 5C21.1046 5 22 5.89543 22 7L22 17Z"
						fill="#6E6E73" />
					<path
						d="M13 12L17 16V13H19V11H17V8L13 12Z"
						fill="#6E6E73" />
					<path
						d="M10.9504 14.5983C10.9504 14.1959 10.8416 13.877 10.6241 13.6414C10.4113 13.401 10.0473 13.1802 9.53191 12.979C9.01655 12.7778 8.5721 12.579 8.19858 12.3828C7.82506 12.1816 7.50355 11.9534 7.23404 11.6982C6.96927 11.4381 6.76123 11.1339 6.60993 10.7855C6.46336 10.4371 6.39007 10.0224 6.39007 9.54154C6.39007 8.71223 6.64539 8.0326 7.15603 7.50263C7.66667 6.97266 8.34515 6.66351 9.19149 6.57519V5H10.3262V6.59727C11.1631 6.71995 11.818 7.08307 12.2908 7.68665C12.7636 8.28531 13 9.06309 13 10.02H10.9504C10.9504 9.43113 10.8322 8.99194 10.5957 8.70242C10.3641 8.40799 10.052 8.26078 9.65957 8.26078C9.27187 8.26078 8.97163 8.3761 8.75887 8.60673C8.5461 8.83246 8.43972 9.14651 8.43972 9.5489C8.43972 9.92184 8.54374 10.2212 8.75177 10.4469C8.95981 10.6726 9.34515 10.9033 9.9078 11.1388C10.4752 11.3743 10.9409 11.5976 11.305 11.8086C11.669 12.0147 11.9764 12.2503 12.2269 12.5152C12.4775 12.7753 12.669 13.0747 12.8014 13.4132C12.9338 13.7469 13 14.137 13 14.5836C13 15.4178 12.7494 16.095 12.2482 16.6151C11.747 17.1353 11.0567 17.442 10.1773 17.5352V19H9.04965V17.5426C8.08038 17.4346 7.32861 17.0789 6.79433 16.4753C6.26478 15.8668 6 15.0596 6 14.0536H8.04965C8.04965 14.6376 8.18203 15.0866 8.44681 15.4006C8.71631 15.7098 9.10165 15.8644 9.60284 15.8644C10.0189 15.8644 10.3475 15.7515 10.5887 15.5258C10.8298 15.2951 10.9504 14.986 10.9504 14.5983Z"
						fill="#6E6E73" />
				</svg>
			</ng-container>
		</pichincha-icon>
	</ng-template>

	<!-- template para label del sidebar -->
	<ng-template
		#typografyTemplate
		let-item="item"
		let-menu="menu">
		<pichincha-typography
			[weight]="menu.isActive ? 'bold' : 'normal'"
			[color]="menu.isActive ? 'blue' : 'darkGrey'"
			[weight_color]="menu.isActive ? '500' : '400'"
			align="inherit"
			[inline_styles]="labelstyle">
			{{ item.title | translate }}
		</pichincha-typography>
	</ng-template>

	<ng-container *ngFor="let item of sidebar; let sectionIndex = index">
		<div
			*ngIf="!item.hidde"
			routerLinkActive
			#menu="routerLinkActive">
			<ng-container *ngIf="item.title !== 'side.Demo' && !item.chields">
				<div
					class="row side"
					[routerLink]="'/' + item.path"
					routerLinkActive="active-link"
					id="{{ item.path }}">
					<div
						class="col-2 p-0 m-0"
						[ngClass]="{ 'margin-auto': menu.isActive }">
						<ng-container
							*ngTemplateOutlet="
								iconTemplate;
								context: { item: item, menu: menu }
							"></ng-container>
					</div>

					<div
						class="col-9 p-0 m-0"
						[ngClass]="{ 'hidden-md': !menu.isActive }">
						<ng-container
							*ngTemplateOutlet="
								typografyTemplate;
								context: { item: item, menu: menu }
							"></ng-container>
					</div>

					<div
						class="row item"
						*ngIf="item.property">
						<div class="col-12 divider"></div>
					</div>
				</div>
			</ng-container>

			<ng-container *ngIf="item.title !== 'side.Demo' && item.chields">
				<div
					class="row side contentchields collapsible"
					[ngClass]="{
						active: optionToggle[sectionIndex],
						'active-link-parent': menu.isActive
					}"
					(click)="toggleSectionVisibility(sectionIndex)">
					<div
						class="col-2 p-0 m-0"
						[ngClass]="{ 'margin-auto': menu.isActive }">
						<ng-container
							*ngTemplateOutlet="
								iconTemplate;
								context: { item: item, menu: menu }
							"></ng-container>
					</div>

					<div
						class="col-7 p-0 m-0 sublevel"
						[ngClass]="{ 'hidden-md': !menu.isActive }">
						<ng-container
							*ngTemplateOutlet="
								typografyTemplate;
								context: { item: item, menu: menu }
							"></ng-container>
					</div>

					<div class="col-2 icon-collapse sublevel">
						<pichincha-icon
							size="big"
							color="blue"
							weight_color="500"
							type="--outlined">
							{{ optionToggle[sectionIndex] ? 'expand_less' : 'expand_more' }}
						</pichincha-icon>
					</div>
				</div>

				<div
					class="col-12 pt-0 pb-0 pr-0 m-0 content"
					[ngClass]="{ visible: optionToggle[sectionIndex] }"
					[routerLinkActive]="['active-link']">
					<ng-container *ngFor="let chield of item.chields">
						<div
							*ngIf="!chield.hidde"
							routerLinkActive
							#menuChield="routerLinkActive"
							class="row side child-level"
							[routerLink]="'/' + chield.path"
							id="{{ chield.path }}">
							<div
								class="col-12 pt-0 pb-0 pr-0 m-0"
								[ngClass]="{ 'hidden-md': !menuChield.isActive }">
								<ng-container
									*ngTemplateOutlet="
										typografyTemplate;
										context: { item: chield, menu: menuChield }
									"></ng-container>
							</div>
						</div>
					</ng-container>
				</div>

				<div
					class="row item"
					*ngIf="item.property">
					<div class="col-12 divider"></div>
				</div>
			</ng-container>
		</div>
	</ng-container>

	<button
		class="btn-default hidden-md"
		(click)="toggleMenu()">
		<pichincha-icon
			size="40px"
			color="blue"
			weight_color="500"
			*ngIf="!hiden">
			chevron_left
		</pichincha-icon>
		<pichincha-icon
			size="40px"
			color="blue"
			weight_color="500"
			*ngIf="hiden">
			chevron_right
		</pichincha-icon>
	</button>
</div>

<div class="hidden-mobile">
	<nav class="mobile-bottom-nav">
		<ng-container *ngFor="let item of mobileMenu">
			<div
				*ngIf="!item.hidde"
				class="row mobile-bottom-nav__item"
				routerLinkActive
				#menu="routerLinkActive">
				{{ item.chields }}
				<div
					class="col-4"
					*ngIf="!item.chields">
					<div
						class="row"
						[routerLink]="'/' + item.path"
						routerLinkActive="active-link">
						<div
							class="col-4"
							*ngIf="item.title !== 'Mas'">
							<pichincha-icon
								size="big"
								[color]="menu.isActive ? 'blue' : 'darkGrey'"
								weight_color="500"
								type="--outlined">
								{{ item.icon }}
							</pichincha-icon>
						</div>
						<div
							class="col-8"
							*ngIf="item.title !== 'Mas'">
							<pichincha-typography
								[weight]="menu.isActive ? 'bold' : 'normal'"
								[color]="menu.isActive ? 'blue' : 'darkGrey'"
								weight_color="500"
								align="inherit">
								{{ item.title | translate }}
							</pichincha-typography>
						</div>
					</div>
				</div>
			</div>
		</ng-container>

		<div
			class="row mobile-bottom-nav__item"
			(click)="openModal()">
			<div class="col-4">
				<div class="row">
					<div class="col-4">
						<pichincha-icon
							size="big"
							color="darkGrey"
							weight_color="500"
							type="--outlined">
							menu
						</pichincha-icon>
					</div>
					<div class="col-8">
						<pichincha-typography
							weight="normal"
							color="darkGrey"
							weight_color="500"
							align="inherit">
							Menu
						</pichincha-typography>
					</div>
				</div>
			</div>
		</div>
	</nav>
</div>
<app-modal
	#modal
	[sidebar]="sidebar"></app-modal>
