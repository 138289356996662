import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'typeDocument',
})
export class TypeDocumentPipe implements PipeTransform {
	transform(value: unknown): string {
		if (value === 'LEGAL_ID') {
			return 'RUC';
		} else if (value === 'RUC') {
			return 'RUC';
		}
		return '';
	}
}
