/**
 * Angular Core / Platform-Browser / Forms
 */
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

/**
 * Pichincha
 */
import { HttpModule, HttpInterceptorRequest, HttpInterceptorResponse } from '@pichincha/angular-sdk/http';
import { StorageModule } from '@pichincha/angular-sdk/storage';
import { EventBusModule } from '@pichincha/angular-sdk/eventbus';
import {
	setAuthorization,
	INTERCEPTOR_CONFIG_STORAGE,
	restartTimer,
	ITimerEvents,
	INTERCEPTOR_TIMER_EVENTS,
} from '@pichincha/bb-commons/interceptor';
import { EStorageType } from '@pichincha/typescript-sdk';
import {
	InternationalizationModule,
	InternationalizationService,
	TRANSLATE_CONFIG,
	TRANSLATE_CONFIG_STORAGE,
} from '@pichincha/bb-commons/internationalization';
import { IdleTimerModule, IdleTimerConfig } from '@pichincha/bb-commons/idle-timer';
import { AuthenticationService } from '@pichincha/bb-commons/authentication';
import { WebSocketModule } from '@pichincha/angular-sdk/websocket';

/**
 * App routing Module
 */
import { AppRoutingModule } from './app-routing.module';

/**
 * Environment
 */
import { environment } from '../environments/environment';

/**
 * Azure
 */
import { MsalGuardConfiguration, MsalInterceptorConfiguration, MsalModule } from '@azure/msal-angular';

/**
 * Components
 */
import { AppComponent } from './app.component';
import { ComponentsModule } from './shared/components/components.module';

/**
 * Layouts
 */
import { DashboardLayoutComponent } from './layouts/dashboard-layout/dashboard-layout.component';
import { FullPageLayoutComponent } from './layouts/full-page-layout/full-page-layout.component';

/**
 * Skeleton
 */
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

/**
 * Pipes
 */
import { PipesModule } from './shared/pipes/pipes.module';
/**
 * Directives
 */
import { DirectivesModule } from './shared/directives/directives.module';

/**
 * Guards
 */
import { LoggedGuard } from './core/guards/logged/logged.guard';
import { UserProfileGuard } from './core/guards/user-profile/user-profile.guard';

/**
 * Config
 */
import { ConfigStorage, AuthApplication } from './core/config/initializer';
import { General } from './core/config/general.enum';

/**
 * Services
 */
import { UserService } from './core/services/user.service';
import { HelperMenuService } from './core/services/menu/helper.menu.service';

/**
 * Google Tag Manager
 */
import { GoogleTagManagerModule } from 'angular-google-tag-manager';

/**
 * Internal InternalizationModule
 */
import { InternationalizationModule as InternationalizationModuleBase } from './core/internationalization/internationalization.module';

/**
 * Config Internationalization
 */
const configInterceptor = {
	storageType: EStorageType.SESSION,
	secretKey: environment.storage.key,
};

/**
 * Pichincha WebSocket Config
 */
const webSocketUrl = environment.websocketUrl;
import { GuidedTourModule, GuidedTourService } from 'ngx-guided-tour';
import { TourService } from './core/services/tour-service/tour.service';

@NgModule({
	declarations: [AppComponent, DashboardLayoutComponent, FullPageLayoutComponent],
	imports: [
		BrowserModule,
		AppRoutingModule,
		FormsModule,
		ReactiveFormsModule,
		DirectivesModule,
		PipesModule,
		ComponentsModule,
		GoogleTagManagerModule.forRoot({
			id: environment.google.tagManagerId,
		}),
		StorageModule.forRoot(ConfigStorage),
		HttpModule.forRoot({ api_url: environment.apiUrl }),
		MsalModule.forRoot(
			AuthApplication,
			{
				interactionType: 'redirect',
				authRequest: {
					scopes: environment.authProvider.scopes,
				},
			} as MsalGuardConfiguration,
			{
				interactionType: 'redirect',
			} as MsalInterceptorConfiguration,
		),
		EventBusModule,
		InternationalizationModule.forRoot(),
		InternationalizationModuleBase,
		IdleTimerModule.forRoot({
			timer: Number(environment.timeSession),
			messages: {
				onEnd: {
					channel: General.CHANNEL_USER,
					topic: General.TOPIC_END_TIMER,
				},
			},
		} as IdleTimerConfig),
		NgxSkeletonLoaderModule,
		WebSocketModule.forRoot(webSocketUrl),
		GuidedTourModule,
	],
	providers: [
		LoggedGuard,
		UserService,
		TourService,
		GuidedTourService,
		UserProfileGuard,
		{
			provide: HttpInterceptorRequest,
			useValue: setAuthorization,
		},
		{
			provide: HttpInterceptorResponse,
			useValue: restartTimer,
		},
		{
			provide: INTERCEPTOR_CONFIG_STORAGE,
			useValue: ConfigStorage,
		},
		{
			provide: TRANSLATE_CONFIG_STORAGE,
			useValue: configInterceptor,
		},
		{
			provide: TRANSLATE_CONFIG,
			useValue: {
				availableLanguages: ['es', 'en'],
				defaultLanguage: 'es',
				urlAssets: environment.translationFiles,
			},
		},
		{
			provide: INTERCEPTOR_TIMER_EVENTS,
			useValue: {
				channel: General.CHANNEL_USER,
				topic: General.TOPIC_RESTART_TIMER,
			} as ITimerEvents,
		},
		InternationalizationService,
		HelperMenuService,
		AuthenticationService,
	],
	bootstrap: [AppComponent],
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
	exports: [InternationalizationModule],
})
export class AppModule {}
