import { Component, ViewChild, ElementRef, Input } from '@angular/core';
import { SidebarInterface } from 'src/app/core/interfaces/sidebar.interface';

@Component({
	selector: 'app-modal',
	templateUrl: './modal.component.html',
	styleUrls: ['./modal.component.scss'],
})
export class ModalComponent {
	@ViewChild('modal', { static: true }) modal: ElementRef | undefined;

	@Input() sidebar?: SidebarInterface[];
	public labelstyle: object = {
		fontSize: '14px',
	};
	data: string = '';

	constructor() {}

	open() {
		if (this.modal) {
			this.modal.nativeElement.style.display = 'block';
		} else {
			this.data = '';
		}
	}

	close() {
		if (this.modal) {
			this.modal.nativeElement.style.display = 'none';
		} else {
			this.data = '';
		}
	}
}
