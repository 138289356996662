import { Component, Input } from '@angular/core';
import { Endpoints } from '../../../core/config/endpoints.enum';
@Component({
	selector: 'app-footer',
	templateUrl: './footer.component.html',
	styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
	@Input() oficialName?: string;
	@Input() ofcialCorreo?: string;
	@Input() oficialTelf?: string;

	currentYear = new Date().getFullYear();

	public route = Endpoints.FOOTER_ROUTE;
	public isOnClick = false;
	public helpTex: string = 'help-text';

	constructor() {}

	public submit() {
		if (!this.isOnClick) {
			this.isOnClick = true;
			this.helpTex = 'help-text-active';
		} else {
			this.isOnClick = false;
			this.helpTex = 'help-text';
		}
	}
}
