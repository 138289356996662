/**
 * Angular Core
 */
import { Injectable } from '@angular/core';
/**
 * Services
 */
import { HttpService } from '@pichincha/angular-sdk/http';
import { EventBusService } from '@pichincha/angular-sdk/eventbus';
import { StorageService } from '@pichincha/angular-sdk/storage';
import { MsalService } from '@azure/msal-angular';
/**
 * Config
 */
import { Endpoints } from './../../core/config/endpoints.enum';
import { General } from './../../core/config/general.enum';
/**
 * Interfaces
 */
import { IExpiredPassword, IUser, ImodalDoubleSession } from './../../core/interfaces/user.interface';
import { ProcessCode } from '../config/process-code.enum';

@Injectable()
export class UserService {
	expiredPasswordModalInfo: IExpiredPassword = {
		expiring: false,
		remainingDays: 0,
	};
	dataModalDuoubleSesion: ImodalDoubleSession = { modalOpen: false };
	dataModalAlertSesion: ImodalDoubleSession = { modalOpen: false };
	guidedTourModal: ImodalDoubleSession = { modalOpen: false };

	constructor(
		private client: HttpService,
		private eventBus: EventBusService,
		private storage: StorageService,
		private msal: MsalService,
	) {}

	/**
	 * getUserInformation.
	 *
	 * @description Method for fetch user logged information
	 * @returns Promise<void>
	 */
	public getUserInformation(): Promise<void> {
		const headers = {
			'process-code': ProcessCode.USER_PROFILE,
			'caller-name': 'app-business-banking-frontend-shell-angular',
		};

		return this.client.get(Endpoints.USER_PROFILE, undefined, undefined, headers).then((x) => {
			const user: IUser = x.data;
			this.eventBus.emit(General.CHANNEL_USER, General.TOPIC_USER, user);
		});
	}

	/**
	 * logout
	 *
	 * @description Method for close session for an user
	 * @returns
	 */
	public logout(): Promise<void> {
		const headers = {
			'Content-Type': 'application/json',
			'process-code': ProcessCode.USER_LOGOUT,
			'caller-name': 'app-business-banking-frontend-shell-angular',
		};

		return this.client
			.post(Endpoints.USER_LOGOUT, {}, undefined, headers)
			.then(async () => {
				this.storage.clear();
				await this.closeSessionAD();
			})
			.catch(async (_e) => {
				await this.closeSessionAD();
			});
	}

	/**
	 * logoutWithoutService
	 *
	 * @description Method for close session in escenarios like
	 * when auth_token doest'n exist in sessionStorage
	 */
	public async logoutWithoutService(): Promise<void> {
		this.storage.clear();
		await this.closeSessionAD();
	}

	/**
	 * closeSessionAD
	 * @description Method for close sesion in AD
	 */
	private async closeSessionAD(): Promise<void> {
		await this.msal.handleRedirectObservable().toPromise();
		await this.msal.logoutRedirect().toPromise();
	}
}
