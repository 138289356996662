/**
 * Angular Core / Router /
 */
import { Injectable, OnDestroy } from '@angular/core';
import { Router, NavigationEnd, RouteConfigLoadEnd } from '@angular/router';

/**
 * RxJS
 */
import { BehaviorSubject, Subscription } from 'rxjs';

/**
 * Lodash
 */
import { some } from 'lodash';

/**
 * Pichincha Services
 */
import { EventBusService } from '@pichincha/angular-sdk/eventbus';
import { IdletimerService } from '@pichincha/bb-commons/idle-timer';
import { StorageService } from '@pichincha/angular-sdk/storage';

/**
 * Services
 */
import { UserService } from './core/services/user.service';

/**
 * Google Tag Manager Service
 */
import { GoogleTagManagerService } from 'angular-google-tag-manager';

/**
 * Config
 */
import { General } from './core/config/general.enum';

/**
 * Guards
 */
import { LoggedGuard } from './core/guards/logged/logged.guard';

@Injectable()
export class AppComponentPresenter implements OnDestroy {
	/**
	 * Propiedad para abrir el modal
	 * de alerta de cierra de sesion
	 */
	public openAlert: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	private susbscription = new Subscription();
	private urlWithoutTimer = ['onboarding', 'state', 'error'];
	public appLoaded: boolean = false;

	constructor(
		private router: Router,
		private idle: IdletimerService,
		private guard: LoggedGuard,
		private storageService: StorageService,
		private event: EventBusService,
		private service: UserService,
		private gtmService: GoogleTagManagerService,
	) {}

	ngOnDestroy(): void {
		this.susbscription.unsubscribe();
		this.idle.stopTimer();
	}
	/**
	 * initSubscriptions
	 *
	 * @description Metodo para iniciar todas las subscripciones
	 */
	public initSubscriptions(): void {
		this.closeSessionSubscription();
		this.gTagSubscription();
		this.sesionTimerSubscription();
		this.timeLeftSubscription();
		this.alertModalSubscription();
		this.checkAppLoad();
	}

	/**
	 * closeSessionSubscription
	 *
	 * @description Subscripcion sobre el eventbus para escuchar cuando se quiere realizar un cierre
	 * de session
	 */
	public closeSessionSubscription(): void {
		this.susbscription.add(
			this.event.on$(General.CHANNEL_USER, General.TOPIC_CLOSE_SESSION).subscribe(() => {
				this.service.logoutWithoutService();
			}),
		);
	}

	/**
	 * sesionTimerSubscription
	 *
	 * @description Subscripcion que se realiza sobre el router para determinar cuando iniciar el contador
	 * del tiempo de sesion
	 */
	public sesionTimerSubscription(): void {
		this.idle.startTimer();
		this.susbscription.add(
			this.router.events.subscribe((event) => {
				if (event instanceof NavigationEnd) {
					const url = event.url;
					const matchUrl = this.isURLPassed(url);
					if (matchUrl) {
						return this.idle.stopTimer(false);
					}
					if (this.idle.isStopped.getValue()) {
						this.idle.resetTimer();
					}
				}
			}),
		);
	}
	/**
	 * gTagSubscription
	 *
	 * @description Subscripcion que se realiza sobre el router para realizar los eventos de cambio
	 * de paginas y notificar a google tag manager
	 */
	public gTagSubscription(): void {
		this.susbscription.add(
			this.router.events.subscribe((event) => {
				if (event instanceof NavigationEnd) {
					const url = event.url;
					this.gtmService.pushTag({
						event: 'pageview',
						data: {
							page_name: url,
						},
					});
				}
			}),
		);
	}
	/**
	 * timeLeftSubscription
	 *
	 * @description Subscripcion para poder determinar el tiempo restarante y levantar las alertas
	 */
	public timeLeftSubscription(): void {
		this.susbscription.add(
			this.idle.timeLeft.subscribe((timer: number) => {
				if (this.router.url.split('/').length > 0 && this.router.url.split('/')[1] !== 'onboarding') {
					this.event.emit(General.CHANNEL_USER, General.TOPIC_SESSION_TIMELEFT, timer);
					if (General.TIME_ALERT_SESSION === timer) {
						this.event.emit(General.CHANNEL_USER, General.TOPIC_WARNING_SESSION, true);
						this.openAlert.next(true);
						this.service.dataModalAlertSesion.modalOpen = true;
					}
					if (1 === timer) {
						setTimeout(() => {
							this.closeSesion(this.router.url);
						}, 1000);
					}
				}
			}),
		);

		this.susbscription.add(
			this.event.on$(General.CHANNEL_USER, General.TOPIC_END_TIMER).subscribe((value) => {
				if (value) {
					this.closeSesion(this.router.url);
					this.openAlert.next(false);
				}
			}),
		);
	}
	/**
	 * alertModalSubscription
	 *
	 * @description Metodo para cerrar el modal de alerta de sesion y reiniciar timer
	 */
	public alertModalSubscription(): void {
		this.susbscription.add(
			this.event.on$(General.CHANNEL_USER, General.TOPIC_CLOSE_ALERT).subscribe((_value) => {
				this.openAlert.next(false);
				this.service.dataModalAlertSesion.modalOpen = false;
				this.idle.resetTimer();
			}),
		);
	}
	/**
	 * closeSesion
	 *
	 * @description Metodo para cerrar la sesion, pero primero analizar si la url
	 * no es la principal o el onboarding
	 * @param url
	 */
	public closeSesion(url: string): void {
		if (!this.isURLPassed(url)) {
			this.service.logoutWithoutService();
		}
	}

	public checkAppLoad() {
		this.susbscription.add(
			this.router.events.subscribe((event) => {
				if (event instanceof RouteConfigLoadEnd) {
					if (!this.appLoaded) {
						this.appLoaded = true;
						const loader = document.getElementById('loader');
						loader?.parentNode?.removeChild(loader);
					}
				}
			}),
		);
	}

	private isURLPassed(url: string): boolean {
		if (url === '/') {
			return true;
		}
		return some(this.urlWithoutTimer, (element) => url.includes(element));
	}
}
