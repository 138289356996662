import { Component, ElementRef, EventEmitter, OnDestroy, Output, ViewChild } from '@angular/core';
import { EventBusService } from '@pichincha/angular-sdk/eventbus';
import { UserService } from '../../../core/services/user.service';

@Component({
	selector: 'app-session-double-active',
	templateUrl: './session-double-active.component.html',
	styleUrls: ['./session-double-active.component.scss'],
})
export class SessionDoubleActiveComponent implements OnDestroy {
	@ViewChild('modal', { static: true }) modal: ElementRef = {} as ElementRef;

	@Output() modalKeep = new EventEmitter();

	constructor(
		private event: EventBusService,
		public userService: UserService,
	) {}

	ngOnDestroy(): void {
		this.userService.dataModalDuoubleSesion.modalOpen = false;
	}

	/**
	 * closeModal
	 *
	 * @description Metodo para cerrar el modal
	 */
	closeModal(): void {
		this.userService.dataModalDuoubleSesion = {
			modalOpen: false,
		};
	}
	/**
	 * logout
	 *
	 * @description Metodo para cerrar la sesion
	 */
	logout(): void {
		this.modalKeep.emit(false);
		this.closeModal();
	}

	/**
	 * keep
	 *
	 * @description Metodo para mantener la sesion activa
	 */
	keep(): void {
		this.modalKeep.emit(true);
		this.closeModal();
	}
}
