import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { SidebarInterface } from '../../../core/interfaces/sidebar.interface';
import { SideService } from '../../../core/services/side-bar/side.service';
import { ModalComponent } from '../modal/modal.component';
import { environment } from '../../../../environments/environment';

@Component({
	selector: 'app-sidebar',
	templateUrl: './side.component.html',
	styleUrls: ['./side.component.scss'],
})
export class SideComponent implements OnInit {
	@Input() sidebar?: SidebarInterface[];

	public mobileMenu: SidebarInterface[] = [
		{
			icon: 'equalizer',
			title: 'Inicio',
			path: 'consolidate-position',
		},

		{
			icon: 'people',
			title: 'Faq',
			path: 'faq',
		},
	];

	public hiden: boolean = false;
	public optionToggle: Record<number, boolean> = {};
	public urlPath: string = '';

	@ViewChild(ModalComponent) modal: ModalComponent | undefined;

	public labelstyle: object = {
		fontSize: '14px',
		cursor: 'pointer',
	};

	constructor(
		private SideService: SideService,
		private router: Router,
	) {}

	ngOnInit(): void {
		this.urlPath = this.router.url.split('/')[1];
		this.checkRutaActiva();
	}

	toggleMenu(): void {
		this.hiden = !this.hiden;
		this.SideService.activeSide$.emit(this.hiden);
	}

	openModal() {
		this.modal?.open();
	}
	redirect() {
		window.open(environment.authProvider.redirectUrl);
	}

	checkRutaActiva() {
		this.sidebar?.forEach((sid, index) => {
			if (sid.chields && sid.chields.some((chi) => chi.path === this.urlPath)) {
				this.optionToggle[index] = true;
			}
		});
	}

	toggleSectionVisibility(sectionIndex: number) {
		const value = this.optionToggle.hasOwnProperty(sectionIndex)
			? !this.optionToggle[sectionIndex]
			: true;

		this.optionToggle[sectionIndex] = this.hiden ? true : value;

		this.hiden = false;
		this.SideService.activeSide$.emit(this.hiden);
	}
}
