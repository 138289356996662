<div
	class="dashboard-layout"
	[ngClass]="{
		'hidden-true': bloqueado,
		'': !bloqueado,
		'clip-overflow-y': showingModal
	}"
	*ngIf="!secondLayout">
	<app-top-header
		(logout)="onLogout()"
		[business]="business"
		[user]="topHeader"></app-top-header>

	<div class="dashboard-layout__panel-side">
		<app-sidebar [sidebar]="sidebar"></app-sidebar>
	</div>

	<main class="dashboard-layout__content-side padding-16 main-content-side">
		<div
			[ngClass]="{
				'div-main-content-side': true,
				'div-1000': !hiden,
				'div-1001': hiden
			}">
			<div class="div-router-main-content-side">
				<router-outlet></router-outlet>
			</div>
		</div>
	</main>

	<div class="dashboard-layout__clearfix"></div>

	<div class="dashboard-layout__content-side">
		<div [ngClass]="{ 'div-1000': !hiden, 'div-1001': hiden }">
			<app-footer
				*ngIf="!simpleFooter; else simple_footer"
				[oficialName]="contactOfficer.fullname"
				[ofcialCorreo]="contactOfficer.email"
				[oficialTelf]="contactOfficer.phoneNumber"></app-footer>

			<ng-template #simple_footer>
				<app-simple-footer></app-simple-footer>
			</ng-template>
		</div>
	</div>
</div>

<div
	class="dashboard-layout"
	[ngClass]="{ 'hidden-true': bloqueado, '': !bloqueado }"
	*ngIf="secondLayout">
	<app-simple-header (logout)="onLogout()"></app-simple-header>

	<main class="dashboard-layout__content main-content">
		<div class="dashboard-layout__main">
			<div class="div-router-main-content">
				<router-outlet></router-outlet>
			</div>
		</div>
	</main>

	<app-simple-footer></app-simple-footer>
</div>

<app-skeleton
	[show]="bloqueado"
	*ngIf="skeleton"></app-skeleton>
<div [ngClass]="{ 'hidden-true': bloqueado, '': !bloqueado }">
	<app-alert-modal [open]="service.guidedTourModal.modalOpen">
		<app-guided-tour-modal></app-guided-tour-modal>

		<!-- TODO: TOUR -->
		<!-- <app-guided-tour-modal
      (openTourEvent)="openTour($event)"
    ></app-guided-tour-modal> -->
	</app-alert-modal>
	<ngx-guided-tour
		skipText="Cancelar"
		nextText="Siguiente"
		backText="Regresar"
		doneText="Finalizar"
		[progressIndicatorLocation]="progressIndicatorLocation"
		[tourStepWidth]="500"></ngx-guided-tour>
</div>
