import { businessInterface } from 'src/app/core/interfaces/business.interface';
import { ICompany } from 'src/app/core/interfaces/user.interface';

export function companyToHeader(data: ICompany): businessInterface {
	return {
		businessName: data.name,
		documentNumber: data.documentNumber,
		documentType: data.documentType,
	} as businessInterface;
}
