import { Component } from '@angular/core';
import { UserService } from '../../../core/services/user.service';
import { StorageService } from '@pichincha/angular-sdk/storage';
import { environment } from '../../../../environments/environment';

@Component({
	selector: 'app-password-alert-modal',
	templateUrl: './password-alert-modal.component.html',
	styleUrls: ['./password-alert-modal.component.scss'],
})
export class PasswordAlertModalComponent {
	constructor(
		public userService: UserService,
		private storageService: StorageService,
	) {}

	closeModal() {
		this.userService.expiredPasswordModalInfo.expiring = false;
	}

	changePassword() {
		const jwtToken = this.storageService.get('access_token');
		const { signinname } = JSON.parse(atob(jwtToken.split('.')[1]));
		const url = `https://${environment.authProvider.domain}/${environment.authProvider.tenantName}/oauth2/v2.0/authorize?p=B2C_1A_BUSINESSBANKING_CHANGEPASSWORD&client_id=${environment.authProvider.clientId}&nonce=defaultNonce&redirect_uri=${environment.authProvider.redirectUrl}&scope=openid&response_type=id_token&prompt=login&paramOne=${signinname}`;
		window.open(url, '_self');
		this.closeModal();
	}
}
