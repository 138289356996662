/**
 * Angular Core
 */
import { EventEmitter, Injectable } from '@angular/core';
/**
 * Interfaces
 */
import { LoadServiceInterface } from 'src/app/core/interfaces/load.interface';

@Injectable({
	providedIn: 'root',
})
export class LoadService implements LoadServiceInterface {
	load = false;

	loadChange: EventEmitter<boolean> = new EventEmitter();

	enableLoad() {
		this.load = true;
		this.loadChange.emit(true);
	}

	disabledLoad() {
		setTimeout(() => {
			this.load = false;
			this.loadChange.emit(false);
		}, 3000);
	}
}
