export const environment = {
	production: false,
	apiUrl: 'https://portaldes.bancaempresasdescal.pichincha.pe/api',
	microApps: 'http://localhost:4200/assets/microapp-local.json',
	mainPath: '/consolidate-position',
	timeSession: 600,
	psswdExpireTime: 7776000,
	psswdMinTimeAlert: 15,
	authProvider: {
		clientId: '6ef600c5-310a-4611-b3d2-d97078fe8342',
		tenantId: 'c7b4d308-3359-4d1a-800f-27ead5d16583',
		tenantName: 'b2cbusinessbankingpedev.onmicrosoft.com',
		domain: 'logindes.bancaempresasdescal.pichincha.pe',
		autority:
			'https://logindes.bancaempresasdescal.pichincha.pe/accountdes.bancaempresasdescal.pichincha.pe/B2C_1A_BUSINESSBANKING_SIGNIN',
		redirectUrl: 'http://localhost:4200',
		accessTokenName: 'access_token',
		authToken: 'auth_token',
		scopes: [
			'openid https://accountdes.bancaempresasdescal.pichincha.pe/6ef600c5-310a-4611-b3d2-d97078fe8342/users.read',
		],
		Apikey: 'b293a52daa62487695559e92b4c3258e',
	},
	storage: {
		key: '<6aR!DZj5)',
		apiKey: 'api_key',
		appName: 'app_name',
		microFront: 'micro_front',
		sessionId: 'uuid',
		language: 'lang',
	},
	google: {
		tagManagerId: 'GTM-5H7SZZH',
		captchaSiteKey: '6LflzHYdAAAAAECB-1sxvYawE4ouBOJ3ZnHNC0PB',
	},
	translationFiles: 'http://localhost:4200/assets/locales/',
	hotJar: 1370437,
	// websocketUrl:
	// 	'wss://apim-develop-business-banking.azure-api.net/channel/business-banking/v1/session-socket?subscription-key=e1cc60f13f0a43e3b76236b426dde84c',
	websocketUrl:
		'https://portaldes.bancaempresasdescal.pichincha.pe/api/channel/business-banking/v1/session-socket/session?subscription-key=fa94a8f68ca9476caa65f398573e5641',

	guideTour: {
		counter: 2,
	},
	websocketConfig: {
		numberOfRetries: 3,
		logout: 1,
	},
};
