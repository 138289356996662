<div>
	<div class="session-alert">
		<div class="session-alert__content custom-scrollbar">
			<div style="text-align: center">
				<div class="session-alert__icon-alert">
					<pichincha-icon
						align="center"
						size="32px"
						color="primary"
						type="--outlined">
						business_center
					</pichincha-icon>
				</div>
			</div>
			<pichincha-typography
				class="mb-8"
				variant="h3"
				color="blue"
				align="center"
				weight="bold"
				[inline_styles]="titleStyle">
				{{ 'guided-tour.title' | translate }}
			</pichincha-typography>
			<pichincha-typography
				variant="bodyText"
				color="darkGrey"
				align="center"
				weight_color="500">
				<strong>
					{{ 'guided-tour.subtitle' | translate }}
				</strong>
				<br />
				{{ 'guided-tour.text' | translate }}
			</pichincha-typography>
		</div>
		<div class="session-alert__footer">
			<div class="items">
				<pichincha-button
					color="primary"
					size="large"
					class="accept"
					(clickbutton)="startTour()">
					{{ 'guided-tour.tour' | translate }}
				</pichincha-button>
			</div>
			<div class="items items_download">
				<pichincha-button
					color="secondary"
					size="large"
					(clickbutton)="closeModal()">
					{{ 'guided-tour.cancel' | translate }}
				</pichincha-button>
			</div>
		</div>
	</div>
</div>
