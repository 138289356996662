import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TypeDocumentPipe } from './type-document/type-document.pipe';

@NgModule({
	declarations: [TypeDocumentPipe],
	imports: [CommonModule],
	exports: [TypeDocumentPipe],
})
export class PipesModule {}
