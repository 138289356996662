export const environment = {
	production: false,
	apiUrl: 'https://portalcal.bancaempresasdescal.pichincha.pe/api',
	microApps: 'https://portalcal.bancaempresasdescal.pichincha.pe/assets/microapp-test.json',
	mainPath: '/consolidate-position',
	timeSession: '300',
	psswdExpireTime: '7776000',
	psswdMinTimeAlert: '15',
	authProvider: {
		clientId: '8c936e4d-ab80-4b3d-859f-4fa1c2831c3c',
		domain: 'logincal.bancaempresasdescal.pichincha.pe',
		tenantId: '24ee6d4a-e147-426f-95c9-076685b5a74f',
		redirectUrl: 'https://portalcal.bancaempresasdescal.pichincha.pe',
		autority: 'https://logincal.bancaempresasdescal.pichincha.pe/accountcal.bancaempresasdescal.pichincha.pe/B2C_1A_BUSINESSBANKING_SIGNIN',
		scopes: ['openid https://accountcal.bancaempresasdescal.pichincha.pe/8c936e4d-ab80-4b3d-859f-4fa1c2831c3c/users.read'],
		accessTokenName: 'access_token',
		authToken: 'auth_token',
		Apikey: 'df374a4638e34c90b45cff354fb40e2f',
		tenantName: 'accountcal.bancaempresasdescal.pichincha.pe',
	},
	storage: {
		key: 'EfQxA5FFcwEsJ4pf',
		apiKey: 'api_key',
		appName: 'app_name',
		microFront: 'micro_front',
		sessionId: 'uuid',
		language: 'lang',
	},
	google: {
		tagManagerId: 'GTM-5H7SZZH',
		captchaSiteKey: '6LcAh2ImAAAAABcEIX7ESCAk_Eml9Z12327pQvQ8',
	},
	translationFiles: 'https://portalcal.bancaempresasdescal.pichincha.pe/assets/locales/',
	hotJar: '1370437',
	websocketUrl:
		'https://portalcal.bancaempresasdescal.pichincha.pe/api/channel/business-banking/v1/session-socket/session?subscription-key=e2a5ff045fd74703a2bf01f710f7cd82',
	guideTour: {
		counter: '2',
	},
	websocketConfig: {
		numberOfRetries: '3',
		logout: '0',
	},
};
