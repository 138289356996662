<div class="footer">
	<div class="row hidden-pc no-gutter-x">
		<div class="col-6 center-footer">
			<pichincha-typography
				variant="smallText"
				color="darkGrey"
				weight="normal"
				weight_color="500">
				&copy;{{ currentYear }} {{ 'footer-translate.2022' | translate }}
			</pichincha-typography>
		</div>
		<span class="br horizontal"></span>
		<div class="col-6 footer-contacto-ubicacion">
			<a
				href="tel:014950550"
				class="footer-contacto">
				<pichincha-typography
					variant="smallText"
					color="darkGrey"
					weight="normal"
					weight_color="500">
					{{ 'footer-translate.contactanos' | translate }} (01) 495 0550
				</pichincha-typography>
			</a>
		</div>
	</div>
	<div class="row hidden-mobile no-gutter-x">
		<div class="col-6 center-footer">
			<pichincha-typography
				variant="smallText"
				class="hidden-pc"
				color="darkGrey"
				weight="normal"
				weight_color="500">
				&copy; {{ 'footer-translate.2022' | translate }}
			</pichincha-typography>
			<pichincha-typography
				class="hidden-mobile"
				variant="smallText"
				color="darkGrey"
				weight="normal"
				weight_color="500"
				font-size="12px">
				&copy; {{ 'footer-translate.2022' | translate }}
			</pichincha-typography>
		</div>
		<div class="col-5">
			<a href="tel:014950550">
				<pichincha-typography
					variant="smallText"
					color="darkGrey"
					weight="normal"
					weight_color="500"
					[inline_styles]="labelstyle"
					>{{ 'footer-translate.contactanos' | translate }} (01) 495 0550
				</pichincha-typography>
			</a>
		</div>
	</div>
</div>
