/**
 * Angular Core
 */
import { Injectable } from '@angular/core';
/**
 * Services
 */
import { HttpService } from '@pichincha/angular-sdk/http';
import { StorageService } from '@pichincha/angular-sdk/storage';

/**
 * Interfaces
 */
import { ITourGuidedJsonFile, ITourGuidedResponse } from '../../interfaces/tour-guided.interface';

/**
 * Config
 */
import { Endpoints } from '../../../core/config/endpoints.enum';
import tourGuidedES from '../../../../assets/locales/guided-tour/tour-es.json';
import tourGuidedEN from '../../../../assets/locales/guided-tour/tour-en.json';
import { environment } from '../../../../environments/environment.local';
import { ProcessCode } from '../../config/process-code.enum';

@Injectable()
export class TourService {
	public loadTourGuided: ITourGuidedJsonFile[] = [];
	public tourServiceArray: ITourGuidedResponse[] = [];
	constructor(
		private http: HttpService,
		private storageService: StorageService,
	) {}

	public getTours() {
		this.loadJsonFile();
		const jwtArray = this.getJwt();
		const dataFilter: ITourGuidedJsonFile[] = this.dataFilter(jwtArray);
		return dataFilter;
	}

	public async checkMaxDisplay() {
		const maxDisplayTour = environment.guideTour.counter;
		let isMaxDisplay: boolean = false;

		const headers = {
			'process-code': ProcessCode.TOURS,
			'caller-name': 'app-business-banking-frontend-shell-angular',
		};

		await this.http
			.get(Endpoints.GET_TOURS, undefined, undefined, headers)
			.then((res) => {
				if (res.data?.length > 0) {
					this.tourServiceArray = res.data;
					isMaxDisplay =
						this.tourServiceArray[0].skipCount < maxDisplayTour &&
						this.tourServiceArray[0].status.code === 'INCOMPLETE';
					return isMaxDisplay;
				} else {
					isMaxDisplay = true;
					return isMaxDisplay;
				}
			})
			.catch((_res) => {
				isMaxDisplay = false;
				return isMaxDisplay;
			});
		return isMaxDisplay;
	}

	public seeLaterTour() {
		const jwtArray = this.getJwt();
		const maxDisplayTour = environment.guideTour.counter;
		const dataFilter: ITourGuidedJsonFile[] = this.dataFilter(jwtArray);
		const headers = {
			'process-code': ProcessCode.TOURS,
			'caller-name': 'app-business-banking-frontend-shell-angular',
			'Content-Type': 'application/json',
		};

		if (this.tourServiceArray.length === 0) {
			const dataSend: ITourGuidedResponse[] = dataFilter.map((step) => {
				return {
					code: step.code,
					skipCount: 1,
					status: {
						code: 'INCOMPLETE',
					},
				};
			});
			this.http.post(Endpoints.GET_TOURS, dataSend, undefined, headers);
		} else {
			const count = this.tourServiceArray[0].skipCount;
			const dataSend: ITourGuidedResponse[] = dataFilter.map((step) => {
				return {
					code: step.code,
					skipCount: count + 1,
					status: {
						code: count + 1 === maxDisplayTour ? 'COMPLETE' : 'INCOMPLETE',
					},
				};
			});
			this.http.post(Endpoints.GET_TOURS, dataSend, undefined, headers);
		}
	}

	public cancelAndFinishTour() {
		const jwtArray = this.getJwt();
		const filterData: ITourGuidedJsonFile[] = this.dataFilter(jwtArray);

		const headers = {
			'process-code': ProcessCode.TOURS,
			'caller-name': 'app-business-banking-frontend-shell-angular',
			'Content-Type': 'application/json',
		};

		if (this.tourServiceArray.length === 0) {
			const sendData: ITourGuidedResponse[] = filterData.map((step) => {
				return {
					code: step.code,
					skipCount: 1,
					status: {
						code: 'COMPLETE',
					},
				};
			});
			this.http.post(Endpoints.GET_TOURS, sendData, undefined, headers);
		} else {
			const counter = this.tourServiceArray[0].skipCount;
			const sendData: ITourGuidedResponse[] = filterData.map((step) => {
				return {
					code: step.code,
					skipCount: counter + 1,
					status: {
						code: 'COMPLETE',
					},
				};
			});
			this.http.post(Endpoints.GET_TOURS, sendData, undefined, headers);
		}
	}

	public dataFilter(jwtArray: any) {
		const filter = this.loadTourGuided.filter((step) => {
			return jwtArray?.includes(step.code);
		});
		return filter ? filter : [];
	}

	public getLanguage(): string {
		const language = this.storageService.get('i18n_profile')
			? this.storageService.get('i18n_profile')
			: 'es';
		return language;
	}

	public loadJsonFile() {
		const language = this.getLanguage();
		switch (language) {
			case 'es':
				this.loadTourGuided = tourGuidedES;
				break;

			case 'en':
				this.loadTourGuided = tourGuidedEN;
				break;

			default:
				this.loadTourGuided = tourGuidedES;
		}
	}

	public getJwt() {
		const jwt = this.storageService.get('access_token');
		if (jwt) {
			return JSON.parse(atob(jwt.split('.')[1]))?.pro_user?.split(',');
		}
	}
}
