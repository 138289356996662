/**
 * Angular Core / Common / Platform
 */
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';

/**
 * Components
 */
import { AlertModalComponent } from './alert-modal/alert-modal.component';
import { EmptyComponent } from './empty/empty.component';
import { FooterComponent } from './footer/footer.component';
import { ModalComponent } from './modal/modal.component';
import { PasswordAlertModalComponent } from './password-alert-modal/password-alert-modal.component';
import { SessionAlertComponent } from './session-alert/session-alert.component';
import { SessionDoubleActiveComponent } from './session-double-active/session-double-active.component';
import { SideComponent } from './sidebar/side.component';
import { SimpleFooterComponent } from './simple-footer/simple-footer.component';
import { SimpleHeaderComponent } from './simple-header/simple-header.component';
import { SkeletonComponent } from './skeleton/skeleton.component';
import { TopComponent } from './top-header/top.component';
import { GuidedTourModalComponent } from './guided-tour-modal/guided-tour-modal.component';

/**
 * Pichincha
 */
import {
	InternationalizationModule,
	InternationalizationService,
	TRANSLATE_CONFIG,
	TRANSLATE_CONFIG_STORAGE,
} from '@pichincha/bb-commons/internationalization';
import { EStorageType } from '@pichincha/typescript-sdk';

/**
 * Internal InternalizationModule
 */
import { InternationalizationModule as InternationalizationModuleBase } from 'src/app/core/internationalization/internationalization.module';

/**
 * Environment
 */
import { environment } from 'src/environments/environment';
import { RouterModule } from '@angular/router';
import { DirectivesModule } from '../directives/directives.module';
import { PipesModule } from '../pipes/pipes.module';

/**
 * Config Internationalization
 */
const configInterceptor = {
	storageType: EStorageType.SESSION,
	secretKey: environment.storage.key,
};

@NgModule({
	declarations: [
		AlertModalComponent,
		EmptyComponent,
		FooterComponent,
		ModalComponent,
		PasswordAlertModalComponent,
		SessionAlertComponent,
		SessionDoubleActiveComponent,
		SideComponent,
		SimpleFooterComponent,
		SimpleHeaderComponent,
		SkeletonComponent,
		TopComponent,
		GuidedTourModalComponent,
	],
	imports: [
		BrowserModule,
		CommonModule,
		InternationalizationModule.forRoot(),
		InternationalizationModuleBase,
		RouterModule,
		DirectivesModule,
		PipesModule,
	],
	exports: [
		AlertModalComponent,
		EmptyComponent,
		FooterComponent,
		ModalComponent,
		PasswordAlertModalComponent,
		SessionAlertComponent,
		SessionDoubleActiveComponent,
		SideComponent,
		SimpleFooterComponent,
		SimpleHeaderComponent,
		SkeletonComponent,
		TopComponent,
		GuidedTourModalComponent,
	],
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
	providers: [
		{
			provide: TRANSLATE_CONFIG_STORAGE,
			useValue: configInterceptor,
		},
		{
			provide: TRANSLATE_CONFIG,
			useValue: {
				availableLanguages: ['es', 'en'],
				defaultLanguage: 'es',
				urlAssets: environment.translationFiles,
			},
		},
		InternationalizationService,
	],
})
export class ComponentsModule {}
