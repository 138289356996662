import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
	selector: 'app-alert-modal',
	templateUrl: './alert-modal.component.html',
	styleUrls: ['./alert-modal.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AlertModalComponent {
	@Input() open: boolean | null;

	constructor() {
		this.open = false;
	}
}
