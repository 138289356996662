<div>
	<div
		class="session-alert"
		*ngIf="userService.expiredPasswordModalInfo">
		<div class="session-alert__header">
			<pichincha-icon
				size="22px"
				type="--sharp"
				weight_color="400"
				(click)="closeModal()"
				>close</pichincha-icon
			>
		</div>
		<div class="session-alert__content custom-scrollbar">
			<div style="text-align: center">
				<div class="pb-24">
					<img
						src="../../../assets/img/info.svg"
						alt="Info image" />
				</div>
			</div>
			<pichincha-typography
				class="mb-8"
				variant="h2"
				color="blue"
				align="center"
				weight_color="500">
				{{ 'password-alert.title' | translate }}
			</pichincha-typography>
			<pichincha-typography
				class="mb-8"
				variant="h2"
				color="blue"
				align="center"
				weight_color="500">
				{{
					'password-alert.title-days'
						| translate: { days: this.userService.expiredPasswordModalInfo.remainingDays }
				}}
			</pichincha-typography>
			<pichincha-typography
				variant="bodyText"
				color="darkGrey"
				align="center"
				weight_color="500">
				{{ 'password-alert.message' | translate }}
			</pichincha-typography>
		</div>
		<div class="session-alert__footer">
			<div class="items items_download">
				<pichincha-button
					color="secondary"
					size="large"
					(clickbutton)="closeModal()">
					{{ 'password-alert.ignore' | translate }}
				</pichincha-button>
			</div>
			<div class="items">
				<pichincha-button
					color="primary"
					size="large"
					class="accept"
					(clickbutton)="changePassword()">
					{{ 'password-alert.change-password' | translate }}
				</pichincha-button>
			</div>
		</div>
	</div>
</div>
