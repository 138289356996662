<div class="footer">
	<!-- <div class="row-footer row hidden-oficial">
    <div class="col-8" [ngClass]="[helpTex]">
      <div class="row row-action">
        <div class="col-12" *ngIf="!isOnClick">
          <pichincha-typography
            variant="bodyText"
            color="darkGrey"
            weight="normal"
            weight_color="400"
          >
            {{ "footer-translate.agente" | translate }}
          </pichincha-typography>
          <pichincha-typography
            variant="bodyText"
            color="darkGrey"
            weight="normal"
            weight_color="400"
          >
            {{ oficialName | titlecase }}
          </pichincha-typography>
        </div>

        <div class="col-12">
          <div class="row row-action footer-row" *ngIf="isOnClick">
            <div class="col-6 call-width">
              <pichincha-typography
                variant="bodyText"
                color="darkGrey"
                weight="normal"
                weight_color="400"
              >
                {{ "footer-translate.agente" | translate }}
              </pichincha-typography>
              <pichincha-typography
                variant="bodyText"
                color="darkGrey"
                weight="normal"
                weight_color="400"
              >
                {{ oficialName | titlecase }}
              </pichincha-typography>
            </div>

            <div class="col-2 row-action">
              <svg
                width="2"
                height="50"
                viewBox="0 0 2 50"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  x="0.5"
                  y="50"
                  width="50"
                  height="1"
                  transform="rotate(-90 0.5 50)"
                  fill="#BEC7DB"
                />
              </svg>
            </div>

            <div class="col-4 row-action">
              <div class="row row-action">
                <div class="col-12">
                  <pichincha-typography
                    variant="bodyText"
                    color="darkGrey"
                    weight="normal"
                    weight_color="400"
                  >
                    {{ "footer-translate.correo" | translate }}:
                    <a class="link-call" href="mailto:{{ ofcialCorreo }}">{{
                      ofcialCorreo
                    }}</a>
                  </pichincha-typography>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <pichincha-typography
                    variant="bodyText"
                    color="darkGrey"
                    weight="normal"
                    weight_color="400"
                  >
                    {{ "footer-translate.phone" | translate }}:
                    <a class="link-call" [href]="'tel:+593' + oficialTelf">{{
                      oficialTelf
                    }}</a>
                  </pichincha-typography>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="help-button col-4">
      <button class="btn-default" (click)="submit()">
        <pichincha-icon size="40px" color="blue" weight_color="500">
          call
        </pichincha-icon>
      </button>
    </div>
  </div> -->
	<div class="row hidden-footer">
		<div class="col-6 center-footer">
			<pichincha-typography
				variant="smallText"
				color="darkGrey"
				weight="normal"
				weight_color="500">
				&copy;{{ currentYear }} {{ 'footer-translate.2022' | translate }}
			</pichincha-typography>
		</div>
		<div class="col-6 footer-contacto-ubicacion">
			<a
				href="tel:014950550"
				class="footer-contacto">
				<pichincha-typography
					variant="smallText"
					color="darkGrey"
					weight="normal"
					weight_color="500">
					{{ 'footer-translate.contactanos' | translate }} (01) 495 0550</pichincha-typography
				>
			</a>
		</div>
	</div>
</div>
