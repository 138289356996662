import { Component, OnDestroy, Input } from '@angular/core';
import { EventBusService } from '@pichincha/angular-sdk/eventbus';
import { Observable, Subscription } from 'rxjs';
/**
 * Config
 */
import { General } from '../../../core/config/general.enum';

@Component({
	selector: 'app-session-alert',
	templateUrl: './session-alert.component.html',
	styleUrls: ['./session-alert.component.scss'],
})
export class SessionAlertComponent implements OnDestroy {
	@Input() open: boolean | null;
	public readonly timer$: Observable<number>;
	private subs$: Subscription = new Subscription();

	constructor(private event: EventBusService) {
		this.open = false;
		this.timer$ = this.event.on$(General.CHANNEL_USER, General.TOPIC_SESSION_TIMELEFT);
	}

	ngOnDestroy(): void {
		if (!this.subs$.closed) {
			this.subs$.unsubscribe();
		}
	}

	/**
	 * closeModal
	 *
	 * @description Metodo para cerrar el modal
	 */
	closeModal(): void {
		this.event.emit(General.CHANNEL_USER, General.TOPIC_CLOSE_ALERT, true);
	}
	/**
	 * logout
	 *
	 * @description Metodo para cerrar la sesion
	 */
	logout(): void {
		this.event.emit(General.CHANNEL_USER, General.TOPIC_CLOSE_SESSION, true);
	}
	/**
	 * keep
	 *
	 * @description Metodo para mantener la sesion activa
	 */
	keep(): void {
		this.event.emit(General.CHANNEL_USER, General.TOPIC_CLOSE_ALERT, true);
	}
}
