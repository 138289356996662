/**
 * Angular Core / Router
 */
import { AfterViewInit, Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';

/**
 * RxJS
 */
import { Observable } from 'rxjs';

/**
 * Pichincha Services
 */
import { StorageService } from '@pichincha/angular-sdk/storage';

/**
 * Services
 */
import { UserService } from './core/services/user.service';

/**
 * Translate Service
 */
import { TranslateService } from '@ngx-translate/core';

/**
 * Presenter
 */
import { AppComponentPresenter } from './app.component.presenter';

/**
 * Environment
 */
import { environment } from '../environments/environment';

/**
 * Guards
 */
import { LoggedGuard } from './core/guards/logged/logged.guard';

import { IdletimerService } from '@pichincha/bb-commons/idle-timer';
import { WebSocketService } from './core/services/web-socket/web-socket.service';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
	providers: [AppComponentPresenter],
})
export class AppComponent implements OnInit, AfterViewInit {
	/**
	 * Propiedad para abrir el modal de alerta
	 * de sesion
	 */
	public open$: Observable<boolean>;
	// private activeConfirmModal: boolean = false;
	private wsConnected: boolean = false;
	idleTime: number = 0;
	idleInterval: any;

	@HostListener('window:beforeunload', ['$event'])
	unloadHandler(_event?: Event) {
		// if (this.wsConnected) this._websocketSrv.closeConnection();
	}

	constructor(
		private translateService: TranslateService,
		private storageService: StorageService,
		private presenter: AppComponentPresenter,
		private router: Router,
		private guard: LoggedGuard,
		public userService: UserService,
		private idle: IdletimerService,
		public websocketSrv: WebSocketService,
	) {
		this.open$ = this.presenter.openAlert.asObservable();
		this.router.routeReuseStrategy.shouldReuseRoute = () => false;
	}

	ngOnInit(): void {
		const url = window.location.href;
		if (url.indexOf('#error=') != -1) this.constructorURL(url);

		/**
		 * Inicializador de todas las subscripciones necesarias para
		 * el correcto funcionamiento de la aplicacion
		 */
		this.storageService.set(environment.storage.apiKey, environment.authProvider.Apikey);
		this.presenter.initSubscriptions();
		// TODO: SOCKET
		// this.checkWebSocketSessionShouldActive();
	}

	private constructorURL(url: string) {
		const items = url.split('&');
		items.length > 1
			? this.router.navigateByUrl(`/error?${items[1]}&${items[2]}`)
			: this.router.navigate(['/']);
	}

	ngAfterViewInit(): void {
		setTimeout(() => {
			this.initView();
		}, 2000);

		// Inicia el temporizador de inactividad
		this.startIdleTimer();

		// Escucha los eventos de actividad del usuario
		window.addEventListener('mousemove', this.refreshUserState.bind(this));
		window.addEventListener('touchmove', this.refreshUserState.bind(this));
		window.addEventListener('touchstart', this.refreshUserState.bind(this));
	}

  ngOnDestroy(): void {
    // Limpia los listeners y el intervalo cuando el componente se destruya
    window.removeEventListener('mousemove', this.refreshUserState.bind(this));
    window.removeEventListener('touchmove', this.refreshUserState.bind(this));
    window.removeEventListener('touchstart', this.refreshUserState.bind(this));

    clearInterval(this.idleInterval);
  }

  // Inicia el temporizador de inactividad
  startIdleTimer() {
    this.idleInterval = setInterval(() => {
      this.idleTime++;
      //console.log(`Tiempo de inactividad: ${this.idleTime} segundos`);
    }, 1250); // Incrementa el contador cada segundo
  }

  // Restablece el contador de inactividad cuando haya actividad
  refreshUserState(event: Event) {
    if (!this.userService.dataModalAlertSesion.modalOpen) {
      //console.log('Actividad detectada. Restableciendo contador de inactividad.');
      this.idleTime = 0; // Restablece el contador
      this.idle.resetTimer(); // Lógica original
    }
  }
	initView() {
		if (this.storageService.get('i18n_profile')) {
			this.obtainLanguageJwt();
			this.guard.startRefreshTokenTimer(this.storageService.get('access_token'));
			this.guard.verifyExpiredPassword();
		}
		// TODO: SOCKET

		this.websocketSrv.connect();
	}

	private obtainLanguageJwt = (): void => {
		const language = this.storageService.get('i18n_profile')
			? this.storageService.get('i18n_profile')
			: 'es';

		this.storageService.set(environment.storage.language, language);
		this.translateService.setDefaultLang(language);
		this.translateService.use(language);
	};

	responseActionModalDoubleSession() {
		this.userService.logoutWithoutService();
	}
}
