import { Component, OnInit, Output, EventEmitter, Input, OnChanges, HostListener } from '@angular/core';
import { businessInterface } from '../../../core/interfaces/business.interface';
import { selectInterface } from '../../../core/interfaces/select.interface';
import { userInterface } from '../../../core/interfaces/user.interface';
import { EventBusService } from '@pichincha/angular-sdk/eventbus';
import { UserService } from '../../../core/services/user.service';
import { StorageService } from '@pichincha/angular-sdk/storage';
import { environment } from '../../../../environments/environment';
import { menuOptionHeader } from '../../../core/constants/user-options-menu.const';

@Component({
	selector: 'app-top-header',
	templateUrl: './top.component.html',
	styleUrls: ['./top.component.scss'],
})
export class TopComponent implements OnInit, OnChanges {
	@Output() logout: EventEmitter<void> = new EventEmitter();
	@Input() user?: any;
	@Input() business?: any;

	public showingModal: boolean = false;
	inicialName: string = '';
	selection: businessInterface = {
		businessName: '',
		documentNumber: '',
		documentType: '',
	};
	public url = '';
	labelInlineStylesTitle: object = {
		fontSize: '14px',
	};
	labelInlineStyleslabel: object = {
		fontSize: '16px',
	};
	labelInlineStyles: object = {
		fontSize: '12px',
	};
	select: any[] = [];
	profileMenu = menuOptionHeader;

	constructor(
		private event: EventBusService,
		public userService: UserService,
		private storageService: StorageService,
	) {}

	ngOnChanges(changes: any): void {
		this.selection =
			changes.business && changes.business.currentValue ? changes.business.currentValue : '';
	}

	ngOnInit(): void {
		this.selectValues();
		this.splitName(this.user);
		this.event.emit('shell', 'send-bottom-header', false);
		this.event.on$('shell', 'send-bottom-header').subscribe((value) => {
			this.showingModal = value as boolean;
		});
		this.changePassword();
	}

	capture(business: any) {
		this.selection = this.business.find(
			(bs: businessInterface) => this.formatValue(bs) == business.detail.value,
		);
	}

	formatValue(business: businessInterface) {
		return `${business.documentType} : ${business.documentNumber}`;
	}

	splitName(user: userInterface) {
		if (user?.firsName) {
			this.inicialName =
				user.firsName.split('')[0] +
				(user.lastname ? user.lastname.split('')[0] : user.firsName.split('')[1]);
		}
	}

	selectValues() {
		this.business?.forEach((value: any, _index: any) => {
			this.selection = {
				businessName: value.businessName,
				documentNumber: value.documentNumber,
				documentType: value.documentType,
			};
			const obj: selectInterface = {
				label: value.documentType + ' : ' + value.documentNumber,
				value: this.formatValue(value),
			};
			this.select.push(obj);
		});
	}

	changePassword() {
		const jwtToken = this.storageService.get('access_token');
		if (jwtToken) {
			const { signinname } = JSON.parse(atob(jwtToken.split('.')[1]));
			this.url = `https://${environment.authProvider.domain}/${environment.authProvider.tenantName}/oauth2/v2.0/authorize?p=B2C_1A_BUSINESSBANKING_CHANGEPASSWORD&client_id=${environment.authProvider.clientId}&nonce=defaultNonce&redirect_uri=${environment.authProvider.redirectUrl}&scope=openid&response_type=id_token&prompt=login&paramOne=${signinname}`;
		}
	}

	showDrop() {
		const showDrop = document.getElementById('dropMenu');
		return showDrop?.classList.toggle('show');
	}

	@HostListener('document:click', ['$event'])
	onDocumentClick(event: MouseEvent) {
		if (
			!(event.target == document.getElementById('button-menu')) &&
			!(event.target == document.getElementById('initials')) &&
			!(event.target == document.getElementById('text-initial')) &&
			!(event.target == document.getElementById('text-name'))
		) {
			const dropdowns = document.getElementById('dropMenu');
			if (dropdowns?.classList.contains('show')) {
				dropdowns?.classList.remove('show');
			}
		}
	}
}
