import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

/**
 * The http loader factory : Loads the files from define path.
 * @param { HttpClient } http
 * @returns { TranslateHttpLoader }
 * @constructor
 */
export function HttpLoaderFactory(http: HttpClient) {
	return new TranslateHttpLoader(http, environment.translationFiles, '.json');
}

@NgModule({
	declarations: [],
	imports: [
		CommonModule,
		HttpClientModule,
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: HttpLoaderFactory,
				deps: [HttpClient],
			},
			defaultLanguage: 'es',
		}),
	],
	exports: [TranslateModule],
})
export class InternationalizationModule {}
