import { Component, Output, EventEmitter } from '@angular/core';
import { Location } from '@angular/common';

@Component({
	selector: 'app-simple-header',
	templateUrl: './simple-header.component.html',
	styleUrls: ['./simple-header.component.scss'],
})
export class SimpleHeaderComponent {
	@Output() logout: EventEmitter<void> = new EventEmitter();

	public labelstyle: object = {
		fontSize: '16px',
		paddingLeft: '10px',
	};

	constructor(private _location: Location) {}
}
