import { Component } from '@angular/core';
import { Endpoints } from '../../../core/config/endpoints.enum';

@Component({
	selector: 'app-simple-footer',
	templateUrl: './simple-footer.component.html',
	styleUrls: ['./simple-footer.component.scss'],
})
export class SimpleFooterComponent {
	public route = Endpoints.FOOTER_ROUTE;
	public isOnClick = false;
	public helpTex: string = 'help-text';

	public labelstyle: object = {
		fontSize: '12px',
		lineHeight: '16px',
	};

	currentYear = new Date().getFullYear();

	constructor() {}
}
