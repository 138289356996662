import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { environment } from '../../../../environments/environment';
import { StorageService } from '@pichincha/angular-sdk/storage';
import { AccountInfo } from '@azure/msal-browser';
import { has } from 'lodash';
/**
 * Types
 */
import { B2CAccount } from '../../types/b2c_account';
import { UserService } from '../../services/user.service';
import { HelperMenuService } from '../../services/menu/helper.menu.service';
import { AuthenticationService } from '@pichincha/bb-commons/authentication';

@Injectable()
export class LoggedGuard implements CanActivate {
	constructor(
		private auth: MsalService,
		private router: Router,
		private storage: StorageService,
		private service: UserService,
		private storageService: StorageService,
		private helperService: HelperMenuService,
		private autenticator: AuthenticationService,
	) {}

	async canActivate(): Promise<boolean> {
		return await this.autenticator.validateCredentials(
			environment.authProvider.accessTokenName,
			environment.authProvider.authToken,
			environment.authProvider.scopes,
			this.auth,
			this.helperService,
			this.storageService,
		);
	}

	/**
	 * setAuthToken
	 *
	 * @description Method for set auth_token into session Storage.
	 * @param account - AccuntInfo from B2C
	 */
	setAuthToken(account: AccountInfo): void {
		if (account) {
			const info = account.idTokenClaims as B2CAccount;
			if (has(info, 'uuid')) {
				this.storage.set(environment.authProvider.authToken, info.uuid);
			}
		}
	}

	async refreshToken() {
		const account = this.auth.instance.getAllAccounts()[0];
		const resp = await this.auth
			.acquireTokenSilent({
				scopes: environment.authProvider.scopes,
				forceRefresh: true,
				account,
			})
			.toPromise();

		this.storage.set(environment.authProvider.accessTokenName, resp.idToken);
		this.storageService.set(environment.storage.appName, 'bussines-banking');
		this.service.getUserInformation();

		this.startRefreshTokenTimer(resp.idToken);
	}

	// helper methods
	// private refreshTokenTimeout: any;

	public startRefreshTokenTimer(jwtToken: any) {
		const jwtTokenBase = JSON.parse(atob(jwtToken.split('.')[1]));
		// set a timeout to refresh the token a minute before it expires
		const expires = new Date(jwtTokenBase.exp * 1000);
		const timeout = expires.getTime() - Date.now() - 60 * 1000;
		setTimeout(() => this.refreshToken(), timeout);
		// this.refreshTokenTimeout = setTimeout(() => this.refreshToken(), timeout);
	}

	// private stopRefreshTokenTimer() {
	//   clearTimeout(this.refreshTokenTimeout);
	// }

	public getPasswordRemainingDays(jwtToken: any) {
		const jwtTokenBase = JSON.parse(atob(jwtToken.split('.')[1]));

		const passwordExpires = new Date(jwtTokenBase.last_pass_user * 1000);
		const remainingTime = Date.now() - passwordExpires.getTime();
		return Number(environment.psswdExpireTime) / (3600 * 24) - remainingTime / (1000 * 3600 * 24);
	}

	public verifyExpiredPassword() {
		const remainingDays = this.getPasswordRemainingDays(this.storageService.get('access_token'));
		if (remainingDays <= Number(environment.psswdMinTimeAlert)) {
			this.service.expiredPasswordModalInfo = {
				expiring: true,
				remainingDays: Number(remainingDays.toFixed(0)),
			};
		}
	}
}
