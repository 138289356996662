<div>
	<div
		*ngIf="open"
		class="modal wrapper">
		<div class="modal__background"></div>
		<div class="modal__content">
			<ng-content></ng-content>
		</div>
	</div>
</div>
