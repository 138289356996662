/**
 * Angular Core
 */
import { Injectable } from '@angular/core';
/**
 * Services
 */
import { UserService } from './../user.service';
/**
 * Constants
 */
import { sidebar } from './const.menu';
import { StorageService } from '@pichincha/angular-sdk/storage';
import { environment } from '../../../../environments/environment';

@Injectable()
export class HelperMenuService {
	constructor(
		private service: UserService,
		private storage: StorageService,
	) {}

	getFilterMenuBase(jwtToken: any) {
		let jwtResponse = JSON.parse(atob(jwtToken.split('.')[1]))?.pro_user?.split(',');

		let uuid = JSON.parse(atob(jwtToken?.split('.')[1]))?.uuid;
		this.storage.set(environment.storage.sessionId, uuid);

		const validation = jwtResponse?.find((jwt: string) => jwt == 'UND');
		if (validation) {
			this.service.logout();
		}
		sidebar.forEach((sid) => {
			if (Array.isArray(sid.chields)) {
				sid.chields = sid.chields.filter((chield) =>
					jwtResponse?.some((jwt: string) => chield.menu == jwt.trim()),
				);
				if (sid.chields.length > 0) jwtResponse.push(sid.menu);
			}
		});
		return sidebar.filter((sid) => jwtResponse?.some((jwt: string) => sid.menu == jwt.trim()));
	}

	getMenuPath(jwt: any) {
		const result = this.getFilterMenuBase(jwt);

		return result.length ? `${result[0].path}` : this.service.logout();
	}
}
