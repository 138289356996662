<div
	id="loader"
	[class]="show ? ' ' : 'hide'">
	<div class="content">
		<div class="top">
			<div class="row top">
				<div class="col-4">
					<pichincha-brand
						type="logo"
						color="white"
						width="100">
					</pichincha-brand>
				</div>
				<div class="col-8 top">
					<ngx-skeleton-loader
						count="1"
						[theme]="{
							'border-radius': '4px',
							height: '8px',
							background: '#E9ECF3',
							width: '320px',
							'margin-bottom': '20px',
							'margin-right': '10px'
						}"
						class="hidden-sm">
					</ngx-skeleton-loader>
					<ngx-skeleton-loader
						count="1"
						[theme]="{
							'border-radius': '50%',
							height: '32px',
							background: '#E9ECF3',
							width: '32px',
							'margin-right': '10px'
						}">
					</ngx-skeleton-loader>
					<ngx-skeleton-loader
						count="1"
						[theme]="{
							'border-radius': '4px',
							height: '8px',
							background: '#E9ECF3',
							width: '144px',
							'margin-bottom': '20px',
							'margin-right': '10px'
						}"
						class="hidden-md">
					</ngx-skeleton-loader>
					<ngx-skeleton-loader
						count="1"
						[theme]="{
							'border-radius': '4px',
							height: '24px',
							background: '#E9ECF3',
							width: '24px',
							margin: '8px',
							'margin-bottom': '15px',
							'margin-right': '10px'
						}"
						class="hidden-sm">
					</ngx-skeleton-loader>
					<ngx-skeleton-loader
						count="1"
						[theme]="{
							'border-radius': '4px',
							height: '8px',
							background: '#E9ECF3',
							width: '88px',
							'margin-bottom': '20px',
							'margin-right': '10px'
						}"
						class="hidden-md">
					</ngx-skeleton-loader>
					<ngx-skeleton-loader
						count="1"
						[theme]="{
							'border-radius': '4px',
							height: '24px',
							background: '#E9ECF3',
							width: '24px',
							margin: '8px',
							'margin-bottom': '15px',
							'margin-right': '10px'
						}">
					</ngx-skeleton-loader>
					<ngx-skeleton-loader
						count="1"
						[theme]="{
							'border-radius': '4px',
							height: '8px',
							background: '#E9ECF3',
							width: '88px',
							'margin-bottom': '20px',
							'margin-right': '10px'
						}"
						class="hidden-md">
					</ngx-skeleton-loader>
				</div>
			</div>
		</div>
		<div class="side">
			<div class="row">
				<div class="col-2">
					<ngx-skeleton-loader
						count="7"
						[theme]="{
							'border-radius': '4px',
							height: '24px',
							background: '#DEE3ED',
							width: '24px',
							'margin-bottom': '50px'
						}">
					</ngx-skeleton-loader>
				</div>
				<div class="col-8">
					<ngx-skeleton-loader
						count="7"
						[theme]="{
							'border-radius': '4px',
							height: '8px',
							background: '#DEE3ED',
							width: '140px',
							'margin-top': '14px',
							'margin-bottom': '50px'
						}">
					</ngx-skeleton-loader>
				</div>
			</div>
		</div>
		<div class="main">
			<div class="seccion">
				<div class="container-fluid">
					<span class="row one">
						<div class="col-12 w-a">
							<ngx-skeleton-loader
								count="1"
								[theme]="{
									'border-radius': '4px',
									height: '8px',
									background: '#E9ECF3',
									'max-width': '240px',
									'min-width': '240px'
								}">
							</ngx-skeleton-loader>
						</div>
					</span>
					<div class="item">
						<div class="row">
							<div class="col-12">
								<ngx-skeleton-loader
									count="1"
									[theme]="{
										'border-radius': '4px',
										height: '16px',
										background: '#DEE3ED',
										'max-width': '280px',
										'min-width': '135px'
									}">
								</ngx-skeleton-loader>
							</div>
							<div class="col-12">
								<ngx-skeleton-loader
									count="1"
									[theme]="{
										'border-radius': '4px',
										height: '8px',
										background: '#DEE3ED',
										width: '100%'
									}">
								</ngx-skeleton-loader>
							</div>
							<div class="col-12">
								<ngx-skeleton-loader
									count="1"
									[theme]="{
										'border-radius': '4px',
										height: '8px',
										background: '#DEE3ED',
										width: '168px'
									}">
								</ngx-skeleton-loader>
							</div>
						</div>
					</div>
					<div class="row">
						<div class="col-12">
							<ngx-skeleton-loader
								count="1"
								[theme]="{
									'border-radius': '4px',
									height: '8px',
									background: '#E9ECF3',
									width: '138px'
								}">
							</ngx-skeleton-loader>
						</div>
						<div class="col-12">
							<ngx-skeleton-loader
								count="1"
								[theme]="{
									'border-radius': '4px',
									height: '32px',
									background: '#E9ECF3',
									width: '260px'
								}">
							</ngx-skeleton-loader>
						</div>
						<div class="col-4">
							<ngx-skeleton-loader
								count="1"
								[theme]="{
									'border-radius': '4px',
									height: '40px',
									background: '#E9ECF3',
									width: '132px'
								}">
							</ngx-skeleton-loader>
						</div>
						<div class="col-4">
							<ngx-skeleton-loader
								count="1"
								[theme]="{
									'border-radius': '4px',
									height: '40px',
									background: '#E9ECF3',
									width: '165px'
								}">
							</ngx-skeleton-loader>
						</div>
						<div class="col-4">
							<ngx-skeleton-loader
								count="1"
								[theme]="{
									'border-radius': '4px',
									height: '40px',
									background: '#E9ECF3',
									width: '56px'
								}">
							</ngx-skeleton-loader>
						</div>
					</div>
				</div>
			</div>
			<div class="article">
				<div class="row">
					<div class="col-1">
						<ngx-skeleton-loader
							count="1"
							[theme]="{
								'border-radius': '4px',
								height: '8px',
								background: '#E9ECF3'
							}">
						</ngx-skeleton-loader>
					</div>
					<div class="col-1">
						<ngx-skeleton-loader
							count="1"
							[theme]="{
								'border-radius': '4px',
								height: '8px',
								background: '#E9ECF3'
							}">
						</ngx-skeleton-loader>
					</div>
				</div>
				<div class="row">
					<div class="col-1">
						<ngx-skeleton-loader
							count="1"
							[theme]="{
								'border-radius': '4px',
								height: '2px',
								background: '#0F265C'
							}">
						</ngx-skeleton-loader>
					</div>
					<div class="col-1">
						<ngx-skeleton-loader
							count="1"
							[theme]="{
								'border-radius': '4px',
								height: '2px',
								background: '#E9ECF3'
							}">
						</ngx-skeleton-loader>
					</div>
					<div class="col-10">
						<ngx-skeleton-loader
							count="1"
							[theme]="{
								'border-radius': '4px',
								height: '2px',
								background: '#E9ECF3'
							}">
						</ngx-skeleton-loader>
					</div>
				</div>
				<div class="row">
					<div class="col-8"></div>
					<div class="col-2 item1">
						<ngx-skeleton-loader
							count="1"
							id="item1"
							[theme]="{
								'border-radius': '4px',
								height: '24px',
								background: '#E9ECF3',
								width: '24px',
								margin: '10px'
							}">
						</ngx-skeleton-loader>
						<ngx-skeleton-loader
							count="1"
							id="item1"
							[theme]="{
								'border-radius': '4px',
								height: '8px',
								background: '#E9ECF3',
								'max-width': '80px',
								'margin-bottom': '20px',
								'margin-right': '10px'
							}">
						</ngx-skeleton-loader>
					</div>
					<div class="col-2 item2">
						<ngx-skeleton-loader
							count="1"
							id="item2"
							[theme]="{
								'border-radius': '4px',
								height: '24px',
								background: '#E9ECF3',
								width: '24px',
								margin: '10px'
							}">
						</ngx-skeleton-loader>
						<ngx-skeleton-loader
							count="1"
							id="item2"
							[theme]="{
								'border-radius': '4px',
								height: '8px',
								background: '#E9ECF3',
								'max-width': '80px',
								'margin-bottom': '20px',
								'margin-right': '10px'
							}">
						</ngx-skeleton-loader>
					</div>
				</div>
				<div class="row cards">
					<div class="col-4 card card-one">
						<ngx-skeleton-loader
							count="1"
							id="card-one"
							[theme]="{
								'border-radius': '4px',
								height: '16px',
								background: '#E9ECF3',
								'max-width': '144px',
								'margin-bottom': '20px',
								'margin-right': '8px'
							}">
						</ngx-skeleton-loader>
						<ngx-skeleton-loader
							count="1"
							id="card-one"
							[theme]="{
								'border-radius': '4px',
								height: '16px',
								background: '#E9ECF3',
								'max-width': '112px',
								'margin-bottom': '50px',
								'margin-right': '24px'
							}">
						</ngx-skeleton-loader>
						<ngx-skeleton-loader
							count="1"
							id="card-one"
							[theme]="{
								'border-radius': '4px',
								height: '8px',
								background: '#E9ECF3',
								'max-width': '120px',
								'margin-bottom': '20px',
								'margin-right': '24px'
							}">
						</ngx-skeleton-loader>
						<ngx-skeleton-loader
							count="1"
							id="card-one"
							[theme]="{
								'border-radius': '4px',
								height: '24px',
								background: '#E9ECF3',
								'max-width': '186px',
								'margin-bottom': '20px',
								'margin-right': '24px'
							}">
						</ngx-skeleton-loader>
						<ngx-skeleton-loader
							count="1"
							id="card-one"
							[theme]="{
								'border-radius': '4px',
								height: '8px',
								background: '#E9ECF3',
								'max-width': '304px',
								'margin-bottom': '20px',
								'margin-right': '24px'
							}">
						</ngx-skeleton-loader>
					</div>
					<div class="col-4 card card-two">
						<ngx-skeleton-loader
							count="1"
							id="card-two"
							[theme]="{
								'border-radius': '4px',
								height: '16px',
								background: '#E9ECF3',
								'max-width': '144px',
								'margin-bottom': '20px',
								'margin-right': '8px'
							}">
						</ngx-skeleton-loader>
						<ngx-skeleton-loader
							count="1"
							id="card-two"
							[theme]="{
								'border-radius': '4px',
								height: '16px',
								background: '#E9ECF3',
								'max-width': '112px',
								'margin-bottom': '50px',
								'margin-right': '24px'
							}">
						</ngx-skeleton-loader>
						<ngx-skeleton-loader
							count="1"
							id="card-two"
							[theme]="{
								'border-radius': '4px',
								height: '8px',
								background: '#E9ECF3',
								'max-width': '120px',
								'margin-bottom': '20px',
								'margin-right': '24px'
							}">
						</ngx-skeleton-loader>
						<ngx-skeleton-loader
							count="1"
							id="card-two"
							[theme]="{
								'border-radius': '4px',
								height: '24px',
								background: '#E9ECF3',
								'max-width': '186px',
								'margin-bottom': '20px',
								'margin-right': '24px'
							}">
						</ngx-skeleton-loader>
						<ngx-skeleton-loader
							count="1"
							id="card-two"
							[theme]="{
								'border-radius': '4px',
								height: '8px',
								background: '#E9ECF3',
								'max-width': '304px',
								'margin-bottom': '20px',
								'margin-right': '24px'
							}">
						</ngx-skeleton-loader>
					</div>
					<div class="col-4 card card-three">
						<ngx-skeleton-loader
							count="1"
							id="card-three"
							[theme]="{
								'border-radius': '4px',
								height: '16px',
								background: '#E9ECF3',
								'max-width': '144px',
								'margin-bottom': '20px',
								'margin-right': '8px'
							}">
						</ngx-skeleton-loader>
						<ngx-skeleton-loader
							count="1"
							id="card-three"
							[theme]="{
								'border-radius': '4px',
								height: '16px',
								background: '#E9ECF3',
								'max-width': '112px',
								'margin-bottom': '50px',
								'margin-right': '24px'
							}">
						</ngx-skeleton-loader>
						<ngx-skeleton-loader
							count="1"
							id="card-three"
							[theme]="{
								'border-radius': '4px',
								height: '8px',
								background: '#E9ECF3',
								'max-width': '120px',
								'margin-bottom': '20px',
								'margin-right': '24px'
							}">
						</ngx-skeleton-loader>
						<ngx-skeleton-loader
							count="1"
							id="card-three"
							[theme]="{
								'border-radius': '4px',
								height: '24px',
								background: '#E9ECF3',
								'max-width': '186px',
								'margin-bottom': '20px',
								'margin-right': '24px'
							}">
						</ngx-skeleton-loader>
						<ngx-skeleton-loader
							count="1"
							id="card-three"
							[theme]="{
								'border-radius': '4px',
								height: '8px',
								background: '#E9ECF3',
								'max-width': '304px',
								'margin-bottom': '20px',
								'margin-right': '24px'
							}">
						</ngx-skeleton-loader>
					</div>
					<div class="col-4 card card-four">
						<ngx-skeleton-loader
							count="1"
							id="card-four"
							[theme]="{
								'border-radius': '4px',
								height: '16px',
								background: '#E9ECF3',
								'max-width': '144px',
								'margin-bottom': '20px',
								'margin-right': '8px'
							}">
						</ngx-skeleton-loader>
						<ngx-skeleton-loader
							count="1"
							id="card-four"
							[theme]="{
								'border-radius': '4px',
								height: '16px',
								background: '#E9ECF3',
								'max-width': '112px',
								'margin-bottom': '50px',
								'margin-right': '24px'
							}">
						</ngx-skeleton-loader>
						<ngx-skeleton-loader
							count="1"
							id="card-four"
							[theme]="{
								'border-radius': '4px',
								height: '8px',
								background: '#E9ECF3',
								'max-width': '120px',
								'margin-bottom': '20px',
								'margin-right': '24px'
							}">
						</ngx-skeleton-loader>
						<ngx-skeleton-loader
							count="1"
							id="card-four"
							[theme]="{
								'border-radius': '4px',
								height: '24px',
								background: '#E9ECF3',
								'max-width': '186px',
								'margin-bottom': '20px',
								'margin-right': '24px'
							}">
						</ngx-skeleton-loader>
						<ngx-skeleton-loader
							count="1"
							id="card-four"
							[theme]="{
								'border-radius': '4px',
								height: '8px',
								background: '#E9ECF3',
								'max-width': '304px',
								'margin-bottom': '20px',
								'margin-right': '24px'
							}">
						</ngx-skeleton-loader>
					</div>
					<div class="col-4 card card-five">
						<ngx-skeleton-loader
							count="1"
							id="card-five"
							[theme]="{
								'border-radius': '4px',
								height: '16px',
								background: '#E9ECF3',
								'max-width': '144px',
								'margin-bottom': '20px',
								'margin-right': '8px'
							}">
						</ngx-skeleton-loader>
						<ngx-skeleton-loader
							count="1"
							id="card-five"
							[theme]="{
								'border-radius': '4px',
								height: '16px',
								background: '#E9ECF3',
								'max-width': '112px',
								'margin-bottom': '50px',
								'margin-right': '24px'
							}">
						</ngx-skeleton-loader>
						<ngx-skeleton-loader
							count="1"
							id="card-five"
							[theme]="{
								'border-radius': '4px',
								height: '8px',
								background: '#E9ECF3',
								'max-width': '120px',
								'margin-bottom': '20px',
								'margin-right': '24px'
							}">
						</ngx-skeleton-loader>
						<ngx-skeleton-loader
							count="1"
							id="card-five"
							[theme]="{
								'border-radius': '4px',
								height: '24px',
								background: '#E9ECF3',
								'max-width': '186px',
								'margin-bottom': '20px',
								'margin-right': '24px'
							}">
						</ngx-skeleton-loader>
						<ngx-skeleton-loader
							count="1"
							id="card-five"
							[theme]="{
								'border-radius': '4px',
								height: '8px',
								background: '#E9ECF3',
								'max-width': '304px',
								'margin-bottom': '20px',
								'margin-right': '24px'
							}">
						</ngx-skeleton-loader>
					</div>
					<div class="col-4 card card-six">
						<ngx-skeleton-loader
							count="1"
							id="card-six"
							[theme]="{
								'border-radius': '4px',
								height: '16px',
								background: '#E9ECF3',
								'max-width': '144px',
								'margin-bottom': '20px',
								'margin-right': '8px'
							}">
						</ngx-skeleton-loader>
						<ngx-skeleton-loader
							count="1"
							id="card-six"
							[theme]="{
								'border-radius': '4px',
								height: '16px',
								background: '#E9ECF3',
								'max-width': '112px',
								'margin-bottom': '50px',
								'margin-right': '24px'
							}">
						</ngx-skeleton-loader>
						<ngx-skeleton-loader
							count="1"
							id="card-six"
							[theme]="{
								'border-radius': '4px',
								height: '8px',
								background: '#E9ECF3',
								'max-width': '120px',
								'margin-bottom': '20px',
								'margin-right': '24px'
							}">
						</ngx-skeleton-loader>
						<ngx-skeleton-loader
							count="1"
							id="card-six"
							[theme]="{
								'border-radius': '4px',
								height: '24px',
								background: '#E9ECF3',
								'max-width': '186px',
								'margin-bottom': '20px',
								'margin-right': '24px'
							}">
						</ngx-skeleton-loader>
						<ngx-skeleton-loader
							count="1"
							id="card-six"
							[theme]="{
								'border-radius': '4px',
								height: '8px',
								background: '#E9ECF3',
								'max-width': '304px',
								'margin-bottom': '20px',
								'margin-right': '24px'
							}">
						</ngx-skeleton-loader>
					</div>
					<div class="col-4 card hidden-md">
						<ngx-skeleton-loader
							count="1"
							id="card-seven"
							[theme]="{
								'border-radius': '4px',
								height: '16px',
								background: '#E9ECF3',
								'max-width': '144px',
								'margin-bottom': '20px',
								'margin-right': '8px'
							}">
						</ngx-skeleton-loader>
						<ngx-skeleton-loader
							count="1"
							id="card-seven"
							[theme]="{
								'border-radius': '4px',
								height: '16px',
								background: '#E9ECF3',
								'max-width': '112px',
								'margin-bottom': '50px',
								'margin-right': '24px'
							}">
						</ngx-skeleton-loader>
						<ngx-skeleton-loader
							count="1"
							id="card-seven"
							[theme]="{
								'border-radius': '4px',
								height: '8px',
								background: '#E9ECF3',
								'max-width': '120px',
								'margin-bottom': '20px',
								'margin-right': '24px'
							}">
						</ngx-skeleton-loader>
						<ngx-skeleton-loader
							count="1"
							id="card-seven"
							[theme]="{
								'border-radius': '4px',
								height: '24px',
								background: '#E9ECF3',
								'max-width': '186px',
								'margin-bottom': '20px',
								'margin-right': '24px'
							}">
						</ngx-skeleton-loader>
						<ngx-skeleton-loader
							count="1"
							id="card-seven"
							[theme]="{
								'border-radius': '4px',
								height: '8px',
								background: '#E9ECF3',
								'max-width': '304px',
								'margin-bottom': '20px',
								'margin-right': '24px'
							}">
						</ngx-skeleton-loader>
					</div>
					<div class="col-4 card hidden-md">
						<ngx-skeleton-loader
							count="1"
							id="card-last"
							[theme]="{
								'border-radius': '4px',
								height: '16px',
								background: '#E9ECF3',
								'max-width': '144px',
								'margin-bottom': '20px',
								'margin-right': '8px'
							}">
						</ngx-skeleton-loader>
						<ngx-skeleton-loader
							count="1"
							id="card-last"
							[theme]="{
								'border-radius': '4px',
								height: '16px',
								background: '#E9ECF3',
								'max-width': '112px',
								'margin-bottom': '50px',
								'margin-right': '24px'
							}">
						</ngx-skeleton-loader>
						<ngx-skeleton-loader
							count="1"
							id="card-last"
							[theme]="{
								'border-radius': '4px',
								height: '8px',
								background: '#E9ECF3',
								'max-width': '120px',
								'margin-bottom': '20px',
								'margin-right': '24px'
							}">
						</ngx-skeleton-loader>
						<ngx-skeleton-loader
							count="1"
							id="card-last"
							[theme]="{
								'border-radius': '4px',
								height: '24px',
								background: '#E9ECF3',
								'max-width': '186px',
								'margin-bottom': '20px',
								'margin-right': '24px'
							}">
						</ngx-skeleton-loader>
						<ngx-skeleton-loader
							count="1"
							id="card-last"
							[theme]="{
								'border-radius': '4px',
								height: '8px',
								background: '#E9ECF3',
								'max-width': '304px',
								'margin-bottom': '20px',
								'margin-right': '24px'
							}">
						</ngx-skeleton-loader>
					</div>
					<div class="col-4 card hidden-md">
						<ngx-skeleton-loader
							count="1"
							id="card-last-md"
							[theme]="{
								'border-radius': '4px',
								height: '16px',
								background: '#E9ECF3',
								'max-width': '144px',
								'margin-bottom': '20px',
								'margin-right': '8px'
							}">
						</ngx-skeleton-loader>
						<ngx-skeleton-loader
							count="1"
							id="card-last-md"
							[theme]="{
								'border-radius': '4px',
								height: '16px',
								background: '#E9ECF3',
								'max-width': '112px',
								'margin-bottom': '50px',
								'margin-right': '24px'
							}">
						</ngx-skeleton-loader>
						<ngx-skeleton-loader
							count="1"
							id="card-last-md"
							[theme]="{
								'border-radius': '4px',
								height: '8px',
								background: '#E9ECF3',
								'max-width': '120px',
								'margin-bottom': '20px',
								'margin-right': '24px'
							}">
						</ngx-skeleton-loader>
						<ngx-skeleton-loader
							count="1"
							id="card-last-md"
							[theme]="{
								'border-radius': '4px',
								height: '24px',
								background: '#E9ECF3',
								'max-width': '186px',
								'margin-bottom': '20px',
								'margin-right': '24px'
							}">
						</ngx-skeleton-loader>
						<ngx-skeleton-loader
							count="1"
							id="card-last-md"
							[theme]="{
								'border-radius': '4px',
								height: '8px',
								background: '#E9ECF3',
								'max-width': '304px',
								'margin-bottom': '20px',
								'margin-right': '24px'
							}">
						</ngx-skeleton-loader>
					</div>
				</div>
			</div>
			<div class="footer">
				<div class="row">
					<div class="col-6">
						<ngx-skeleton-loader
							count="1"
							[theme]="{
								'border-radius': '4px',
								height: '8px',
								background: '#E9ECF3',
								'margin-bottom': '20px',
								'margin-right': '10px'
							}">
						</ngx-skeleton-loader>
					</div>
					<div class="col-6">
						<ngx-skeleton-loader
							count="1"
							[theme]="{
								'border-radius': '4px',
								height: '8px',
								background: '#E9ECF3',
								'margin-bottom': '20px',
								'margin-right': '10px'
							}">
						</ngx-skeleton-loader>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
