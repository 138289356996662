import { Component, OnInit } from '@angular/core';
import { EventBusService } from '@pichincha/angular-sdk/eventbus';
import { isEmpty } from 'lodash';
/**
 * Interfaces
 */
import { SidebarInterface } from '../../core/interfaces/sidebar.interface';
import { IcontactInterface, IUser, userInterface } from '../../core/interfaces/user.interface';
import { businessInterface } from '../../core/interfaces/business.interface';
import { ITourGuidedJsonFile } from './../../core/interfaces/tour-guided.interface';
/**
 * Services
 */
import { UserService } from './../../core/services/user.service';
import { SideService } from './../../core/services/side-bar/side.service';
import { TourService } from './../../core/services/tour-service/tour.service';
/**
 * Config
 */
import { General } from '../../core/config/general.enum';
import { companyToHeader } from './../../shared/utils/adapter/adapter.util';
import { Router, Event, NavigationStart } from '@angular/router';
import { LoadService } from './../../core/services/load-service/load.service';
import { StorageService } from '@pichincha/angular-sdk/storage';
import { HelperMenuService } from './../../core/services/menu/helper.menu.service';
/**
 * Guided Tour
 */
import { GuidedTourService, ProgressIndicatorLocation } from 'ngx-guided-tour';
import { environment } from '../../../environments/environment';

@Component({
	selector: 'app-dashboard-layout',
	templateUrl: './dashboard-layout.component.html',
	styleUrls: ['./dashboard-layout.component.scss'],
})
export class DashboardLayoutComponent implements OnInit {
	public user: IUser = {} as IUser;
	bloqueado: boolean = true;
	hiden: boolean = false;
	valor: boolean = false;
	skeleton: boolean = false;
	public secondLayout: boolean = false;
	public simpleFooter: boolean = false;
	public showingModal: boolean = false;
	business: businessInterface[] = [];
	progressIndicatorLocation = ProgressIndicatorLocation.TopOfTourBlock;
	public guidedTours: ITourGuidedJsonFile[] = [];

	topHeader: userInterface = {
		firsName: 'Cabo ',
		lastname: 'Burbano',
	};

	contactOfficer: IcontactInterface = {
		fullname: '',
		email: '',
		phoneNumber: '',
	};

	sidebar: SidebarInterface[] = [];

	constructor(
		public service: UserService,
		private sideService: SideService,
		private event: EventBusService,
		private router: Router,
		private readonly _loadService: LoadService,
		private storageService: StorageService,
		private helperService: HelperMenuService,
		private guidedTourService: GuidedTourService,
		private tourService: TourService,
	) {
		this.router.events.subscribe((event: Event) => {
			if (event instanceof NavigationStart) {
				this.secondLayout =
					event.url.includes('user-creation') ||
					event.url.includes('order-send') ||
					event.url.includes('softoken');
			}
		});
	}

	ngOnInit(): void {
		this.storageService.set(
			environment.storage.language,
			this.storageService.get('i18n_profile') ? this.storageService.get('i18n_profile') : 'es',
		);
		this.storageService.set(environment.storage.appName, 'bussines-banking');
		this._loadService.enableLoad();
		this.router.events.subscribe();
		const jwt = this.storageService.get('access_token');
		jwt ? (this.sidebar = this.helperService.getFilterMenuBase(jwt)) : (this.sidebar = []);
		this.sidebar = this.sidebar.filter((s) => s.title !== '');
		this.getSkeleton();
		this.event.emit('shell', 'send-bottom-header', false);
		this.event.on$('shell', 'send-bottom-header').subscribe((value) => {
			this.showingModal = value as boolean;
		});

		if (this.router.url.includes('user-detail/user-confirm-modification')) this.secondLayout = true;
		else
			this.secondLayout =
				this.router.url.includes('user-creation') ||
				this.router.url.includes('order-send') ||
				this.router.url.includes('order-approve') ||
				this.router.url.includes('softoken');
		this.simpleFooter = this.router.url.includes('orders-administration');
		this.listenLoadService();
		this.user = this.event.getValue(General.CHANNEL_USER, General.TOPIC_USER);
		if (!isEmpty(this.user)) {
			this.contactOfficer = {
				fullname: this.user.contactOfficer?.fullname || '',
				email: this.user.contactOfficer?.email || '',
				phoneNumber: this.user.contactOfficer?.phoneNumber || '',
			};
			this.topHeader = {
				firsName: this.user.firstName,
				lastname: this.user.fatherLastName,
			};
			this.business = this.user.company ? [companyToHeader(this.user.company)] : [];
		}

		this.side();
		this.getToursData();
		if (this.router.url.includes('consolidate-position')) {
			// TODO: descomentar par el tour
			// this.tourService.checkMaxDisplay().then( res=> this.service.guidedTourModal.modalOpen = res);
			this.service.guidedTourModal.modalOpen = false;
		} else {
			this.service.guidedTourModal.modalOpen = false;
		}
		this._loadService.disabledLoad();
	}

	async onLogout(): Promise<void> {
		return this.service.logout();
	}

	side() {
		this.sideService.activeSide$.subscribe((res: boolean) => {
			this.hiden = res;
		});
	}

	public listenLoadService() {
		this._loadService.loadChange.subscribe((cambio: any) => {
			this.bloqueado = cambio;
		});
	}

	public getSkeleton() {
		this.skeleton = true;
	}

	// openTour(event: any) {
	//   const steps = this.guidedTours;
	//   this.service.guidedTourModal.modalOpen = false;
	//   this.guidedTourService.startTour({
	//     tourId: "tour",
	//     useOrb: false,
	//     steps,
	//     preventBackdropFromAdvancing: true,
	//     skipCallback: () => {
	//       this.tourService.cancelAndFinishTour();
	//     },
	//     completeCallback: () => {
	//       this.tourService.cancelAndFinishTour();
	//     },
	//   });
	// }

	getToursData() {
		// TODO: descomentar par el tour
		// this.guidedTours = this.tourService.getTours()
	}
}
