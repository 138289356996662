<div>
	<div
		class="session-alert"
		*ngIf="open">
		<div class="session-alert__header">
			<pichincha-icon
				size="22x"
				type="--sharp"
				weight_color="400"
				(click)="closeModal()"
				>close</pichincha-icon
			>
		</div>
		<div class="session-alert__content custom-scrollbar">
			<div style="text-align: center">
				<div class="session-alert__icon-alert">
					<pichincha-icon
						weight_color="500"
						align="center"
						size="30px"
						color="warning"
						type="--round">
						report_problem
					</pichincha-icon>
				</div>
			</div>
			<pichincha-typography
				class="mb-8"
				variant="h3"
				color="blue"
				align="center"
				weight_color="500">
				{{ 'alert_session.title' | translate }}
			</pichincha-typography>
			<pichincha-typography
				*ngIf="timer$ | async as data"
				variant="bodyText"
				color="darkGrey"
				align="center"
				weight_color="500">
				{{ 'alert_session.message' | translate: { seconds: data } }}
			</pichincha-typography>
		</div>
		<div class="session-alert__footer">
			<div class="items items_download">
				<pichincha-button
					color="secondary"
					size="large"
					(clickbutton)="logout()">
					{{ 'alert_session.logout' | translate }}
				</pichincha-button>
			</div>
			<div class="items">
				<pichincha-button
					color="primary"
					size="large"
					class="accept"
					(clickbutton)="keep()">
					{{ 'alert_session.keep' | translate }}
				</pichincha-button>
			</div>
		</div>
	</div>
</div>
